.shop-one-time-messages {
	& + * {
		@include resp-margin(top);
	}

	.session-message {
		&:after {
			content: '';
			display: none;
		}
	}
}