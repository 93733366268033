@use "sass:math";

#grasenhiller_checkoutpage {
	.main {
		@extend %cf;
	}

	#Form_UpdateCartForm {
		float: left;
		width: calc((100% - #{$column-width}) / 2);
		margin-right: $column-width;

		@include breakpoint(null, $bp-l) {
			width: 100%;
			float: none;
			margin-bottom: $dist;
			margin-right: 0;
		}
	}
	
	#Form_OrderSummaryForm {
		width: calc((100% - #{$column-width}) / 2);
		float: left;
		border: 2px solid $border-color;
		padding: $dist;

		@include breakpoint(null, $bp-l) {
			width: 100%;
			float: none;
		}

		@include breakpoint(null, $bp-s) {
			padding: math.div($dist, 1.5);
		}

		fieldset {
			.field {
				.description {
					a {
						@extend %button--extra-small;

						&:before {
							@include fontawesome($fa-var-plus);
						}
					}
				}
			}
		}
	}
}