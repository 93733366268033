@use "sass:math";

.two-fields-in-line {
	@extend %cf;

	& > .fieldgroup > .fieldgroup-field {
		float: left;
		width: calc(50% - #{$dist*0.5});
		margin-top: $dist;

		&:nth-of-type(-n+2) {
			margin-top: 0;
		}

		&:nth-of-type(2n+2) {
			margin-left: $dist;

			@include breakpoint(null, $bp-s) {
				margin-top: math.div($dist, 3);
			}
		}

		&:nth-of-type(2n+3) {
			clear: both;
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;

			&:nth-of-type(2n+2) {
				margin-left: 0;
				margin-top: math.div($dist, 1.5);
			}
		}
	}
}

.fieldgroup {
	.fieldholder-small-label {
		display: block;
		font-weight: $fw-bold;
		margin-bottom: $dist*0.5;
	}
}