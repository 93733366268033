@use "sass:math";

.placed-orders {
	@extend %cf;

	.order {
		border: 2px solid $border-color;
		padding: math.div($dist, 1.5) $dist $dist $dist;

		@include breakpoint(null, $bp-m) {
			padding: $dist*0.5;
		}

		&__top {
			border-bottom: 2px solid $border-color;
			margin-bottom: $dist*0.5;
			padding-bottom: $dist*0.5;

			h3 {
				font-size: $fs-h4;
				font-weight: $fw-h-medium;
				color: $hc;

				@include breakpoint(null, $bp-m) {
					font-size: $fs-h5;
				}

				a {
					color: $hc;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			small {
				text-transform: uppercase;
				font-family: $ff-hs;
				font-weight: $fw-medium;
				color: $fc-light;
			}
		}

		&__middle {
			@extend %cf;
			margin-bottom: $dist*0.5;

			p {
				width: calc(50% - #{$dist});
				float: left;

				&:first-of-type {
					margin-right: $dist;

					@include breakpoint($bp-l, $bp-xl) {
						width: 100px;
					}
				}

				&:last-of-type {
					@include breakpoint($bp-l, $bp-xl) {
						width: calc(100% - (#{$dist} + 100px));
					}
				}

				@include breakpoint(null, $bp-m) {
					width: 100%;
					margin-right: 0;
				}
			}
		}

		&__bottom {
			@extend %background-pattern;
			@extend %cf;
			padding: math.div($dist, 1.5);

			@include breakpoint(null, $bp-m) {
				padding: $dist*0.5;
			}

			p {
				width: calc(50% - #{$dist});
				float: left;

				&:before {
					margin-right: math.div($dist, 3);
				}

				&:first-of-type {
					margin-right: $dist;

					&:before {
						@include fontawesome($fa-var-coins);
					}
				}

				&:last-of-type {
					&:before {
						@include fontawesome($fa-var-shipping-fast);
					}
				}
			}

			.placed-orders__payed--unpaid {
				color: $invalid-color !important;
			}

			.placed-orders__payed {
				color: $ac;
			}
		}

		.button--small {
			margin-top: $dist*0.5;
			display: inline-block;

			&:before {
				@include fontawesome($fa-var-money-check-alt);
				margin-right: $dist*0.25;
				width: $dist;
			}
		}
	}
}

body.has-sidebar {
	.placed-orders {
		$grid-conf: (
			(null, $bp-s, 1),
			($bp-s, null, 2)
		);

		.order {
			@include resp-gallery-layout($grid-conf);
		}
	}
}

body:not(.has-sidebar) {
	.placed-orders {
		$grid-conf: (
			(null, $bp-s, 1),
			($bp-s, $bp-l, 2),
			($bp-l, null, 3)
		);

		.order {
			@include resp-gallery-layout($grid-conf);
		}
	}
}