.news-page {
  &__preview-text {
    @include resp-margin(bottom);

    p {
      font-size: $fs-h3;
    }
  }

  &__text {
    @extend %cf;

    .news-page__image {
      display: block;
      width: span(4 of 12);
      float: right;
      margin-left: $dist*0.5;
      margin-bottom: $dist*0.25;
      @include image-hover();

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &__meta {
    margin-top: $dist;
    padding-top: $dist*0.5;
    border-top: 2px solid $border-color;

    span {
      font-family: $ff-hs;
      font-size: $fs-small;
      font-weight: $fw-medium;
      color: $fc-light;
      text-transform: uppercase;
    }

    ul {
      @extend %cf;
      margin: $dist 0 0 0;
      padding: 0;

      li {
        display: block;

        &.news-page__prev {
          float: left;

          a {
            @extend %button--small;

            &:before {
              @include fontawesome($fa-var-long-arrow-left, $style: regular);
            }
          }
        }

        &.news-page__next {
          float: right;

          a {
            @extend %button--small;

            &:after {
              @include fontawesome($fa-var-long-arrow-right, $style: regular);
              margin-left: $dist*0.25;
            }

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}