.module--textimage {
  .module__inner {
    .textimage__image a,
    a.textimage__image {
      display: block;

      &[data-lightbox] {
        @include image-hover();
      }

      &:not([data-lightbox]) {
        @include image-hover($fa-var-arrow-right);
      }
    }
  }

  .textimage {
    @extend %cf;
    
    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &--image-top {
      .textimage__image {
        @include resp-margin(bottom);
      }
    }

    &--image-bottom {
      .textimage__image {
        @include resp-margin(top);
      }
    }

    .formatted-text {
      .textimage__title {
        margin-top: 0 !important;
      }
    }

    &--image-left,
    &--image-right {
      .textimage__image {
        width: span(5 of 15);
        
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .formatted-text {
        width: span(10 of 15);
        float: left;
      }

      @include breakpoint(null, $bp-s) {
        .textimage__image {
          float: none !important;
          margin: 0;
          width: 100%;

          & + .formatted-text {
            margin-top: $dist*0.5 !important;
          }
        }

        .formatted-text {
          float: none;
          margin: 0;
          width: 100%;

          & + .textimage__image {
            margin-top: $dist*0.5 !important;
          }
        }
      }

      @include breakpoint($bp-s, $bp-m) {
        .textimage__image {
          width: span(7 of 15);
        }

        .formatted-text {
          width: span(8 of 15);
        }
      }
    }

    &--image-left {
      .textimage__image {
        float: left;
        margin-right: gutter(15);
      }
    }

    &--image-right {
      .textimage__image {
        float: right;
        margin-left: gutter(15);
      }
    }

    &--text-floating {
      .formatted-text {
        width: 100%;

        p {
          overflow: visible;
        }
      }
      
      .textimage__image {
        display: block;
        float: left;
        margin-bottom: $dist*0.25;

        @include breakpoint(null, $bp-s) {
          margin: 0 !important;
          margin-bottom: $dist*0.5 !important;
        }
      }

      &.textimage--image-right {
        .textimage__image {
          float: right;
        }
      }
    }
  }
}
