@use "sass:math";

#Form_AddToCartForm {
	max-width: 540px;
	margin-right: auto;
	margin-left: auto;
	margin-top: $dist*2.5;
	position: relative;
	@extend %cf;

	@include breakpoint(null, $bp-m) {
		margin-top: $dist;
	}

	.field {
		margin-bottom: 0;
	}

	.message {
		margin-bottom: math.div($dist, 1.5);
	}

	.system-message + *,
	.message:not([style*="display: none"]) + * {
		margin-top: 0 !important;
	}

	fieldset {
		.field {
			border: 1px solid $fc;
			@extend %cf;

			label {
				background: #ffffff;
				padding: math.div($dist, 1.5) 0;
				margin: $dist*0.1 0 $dist*0.1 $dist*0.1;

				@include breakpoint(null, $bp-s) {
					padding: 11px 0;
				}
			}

			.middleColumn {
				margin: $dist*0.1 $dist*0.1 $dist*0.1 0;

				input {
					line-height: 1em;
					padding: 18px 0;
					text-align: center;
					background: $border-color;
					border: none;

					@include breakpoint(null, $bp-s) {
						padding: 9px 0;
					}

					&:focus {
						border: none;
					}
				}
			}
		}
	}

	.add-to-cart-form__variant {
		width: 100%;
		float: none;
		margin-bottom: $dist*0.5;

		.field {
			label {
				width: calc(126px - (#{$dist} / 10));
				float: left;

				@include breakpoint(null, $bp-s) {
					width: calc(80px - (#{$dist} / 10));
				}
			}

			.middleColumn {
				width: calc(100% - (126px + (#{$dist} / 10)));
				float: left;

				@include breakpoint(null, $bp-s) {
					font-size: $fs-small;
					width: calc(100% - (80px + (#{$dist} / 10)));
				}

				select {
					background: $box-color;

					&:focus {
						border-color: $box-color;
					}
				}
			}
		}
	}

	.add-to-cart-form__count {
		width: calc((100% - #{$dist}) / 2);
		margin-right: $dist;
		float: left;

		@include breakpoint(null, 500px) {
			width: 100%;
			float: none;
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			margin-bottom: $dist*0.5;
		}

		.field {
			label,
			.middleColumn {
				width: calc(50% - (#{$dist} / 10));
				float: left;
			}

			.middleColumn {
				@include breakpoint(null, $bp-s) {
					width: calc(100% - (80px + (#{$dist} / 10)));
				}
			}

			label {
				@include breakpoint(null, $bp-s) {
					width: calc(80px - (#{$dist} / 10));
				}
			}
		}
	}

	.btn-toolbar {
		width: calc((100% - #{$dist}) / 2);
		float: left;

		@include breakpoint(null, 500px) {
			width: 100%;
			float: none;
		}

		input {
			padding: 23px $dist 25px $dist;
			width: 100%;

			@include breakpoint(null, $bp-s) {
				padding: $dist*0.5 32px;
			}
		}

		& > span {
			@include center(h);
			display: inline-block;
			font-size: $fs-small;
			color: $fc-light;
			text-transform: uppercase;
			font-weight: $fw-medium;
			font-family: $ff-hs;
			bottom: -$dist*1.5;
		}
	}

	#Form_ItemEditForm_StockNotice {
		width: 100%;
		float: left;
		font-size: $fs-small;
		padding: 0;
		margin: 0;
		margin-top: $dist*0.5;
		color: $fc-light;
	}

	label.right {
		width: 100%;
		margin-top: $dist*0.5;
		font-size: $fs-small;
		font-family: $ff-hs;
		text-transform: uppercase;
		font-weight: $fw-medium;
		display: inline-block;
		color: $fc-light;
	}
}