.cookie-confirm {
  @include resp-padding(v);
  @include resp-padding(h);

  position: fixed;
  z-index: 999999999999;
  right: calc(#{$dist} * 0.5);
  bottom: calc(#{$dist} * 0.5);
  width: 100%;
  max-width: calc(100% - #{$dist} * 0.5);
  max-height: 90%;
  background: #ffffff;
  box-shadow: 5px 5px 20px -10px #000000;
  overflow: auto;

  @include breakpoint($bp-l, null) {
    max-width: 50%;
  }

  @include breakpoint($bp-m, $bp-l) {
    max-width: 75%;
  }

  @include breakpoint(null, $bp-m) {
    max-height: 75% !important;
    right: calc(#{$dist} / 4);
    bottom: calc(#{$dist} / 4);
  }

  &__top {
    & > span {
      display: block;
      margin-bottom: calc(#{$dist} * 0.5);
      font-family: $ff-h;
      font-size: $fs-h3;
      font-weight: $fw-h-bold;
      line-height: $line-height;
    }

    & > b {
      display: block;
      margin-top: calc(#{$dist} * 0.5);
    }

    & > p {
      margin: 0;
    }
  }

  &__bottom {
    display: grid;
    justify-items: start;

    @include breakpoint($bp-s) {
      grid-auto-columns: min-content auto;
      grid-template-areas:
                'checkboxes checkboxes'
                'submit cancel'
                'select-current select-current';
    }

    @include breakpoint(null, $bp-s) {
      grid-template-areas:
                'checkboxes'
                'submit'
                'cancel'
                'select-current';
    }

    & > br {
      display: none;
    }
  }

  &__checkboxes {
    position: relative;
    margin-bottom: $dist;
    text-align: left;
    grid-area: checkboxes;
  }

  &__checkbox {
    position: relative;
    display: block;
    margin-top: calc(#{$dist} / 2);

    &:first-of-type {
      margin-top: 0 !important;
    }

    input {
      height: 100%;
      border-radius: $radius;
      overflow: hidden;
    }

    span {
      display: block;
      margin-top: 0.5em;
      margin-left: calc(1.5rem + 3px);
      color: $fc-light;
      font-size: $fs-small;
      line-height: $line-height;
    }
  }
}

#cookie-confirm__submit,
#cookie-confirm__cancel {
  width: max-content;
  text-align: left;
  white-space: nowrap;
}

#cookie-confirm__submit {
  @extend %button;

  grid-area: submit;

  @include breakpoint($bp-s) {
    margin-right: calc(#{$dist} * 0.5);
  }
}

#cookie-confirm__cancel {
  @extend %button;

  grid-area: cancel;

  @include breakpoint(null, $bp-s) {
    margin-top: calc(#{$dist} * 0.5);
  }
}

#cookie-confirm__select-current {
  @include resp-margin(top, 0.5, $bp-s);

  display: inline-block;
  cursor: pointer;
  grid-area: select-current;
}

#cookie-revoke {
  cursor: pointer;
  position: fixed;
  right: 75px;
  bottom: 0;
  z-index: 9999999999;
  padding: 10px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px -10px #000000;

  &:hover {
    color: $ac;
  }
}

