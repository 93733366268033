@use "sass:math";

.product-page {
	&__top {
		@extend %cf;
	}

	&__bottom {
		margin-top: $dist*2;

		@include breakpoint(null, $bp-m) {
			margin-top: $dist;
		}
	}

	&__left {
		float: left;

		@include breakpoint(null, $bp-m) {
			border: 2px solid $border-color;
			margin-top: $dist*0.5;
			padding: $dist*0.5;
		}

		@include breakpoint(null, $bp-s) {
			padding: math.div($dist, 3);
		}

		a {
			@include image-hover();
			display: block;

			img {
				@include resp-img();
			}
		}

		ul {
			@extend %cf;

			li {
				display: block;
				float: left;

				img {
				}
			}

			.slick-arrow {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 999;
				background: $border-color;
				font-size: $fs-h6;
				font-weight: $fw-h-medium;
				padding: $dist*0.5;
				cursor: pointer;
				transition: $duration;

				&:hover {
					background: $border-color-dark;
				}

				@include breakpoint(null, $bp-s) {
					padding: math.div($dist, 3);
				}

				&:first-of-type {
					@include breakpoint(null, $bp-m) {
						bottom: auto;
						@include center(v);
					}
				}
			}

			.fa-long-arrow-right {
				left: 47px;

				@include breakpoint(null, $bp-m) {
					bottom: auto;
					@include center(v);
					left: auto;
					right: 0;
				}
			}
		}

		@include breakpoint(null, $bp-m) {
			.slick-slide {
				margin-right: math.div($dist, 3);
				margin-left: math.div($dist, 3);
			}

			.slick-list {
				margin-right: math.div(-$dist, 3);
				margin-left: math.div(-$dist, 3);
			}
		}
	}

	&__right {
		float: right;
		text-align: center;
		position: relative;
		@extend %background-pattern;

		.shop-product-instock {
			position: absolute;
			top: $dist;
			right: math.div(-$dist, 3);
			z-index: 999;

			&--in span {
				background: $valid-color;
			}

			&--soon span {
				background: $warning-color;
			}

			&--out span {
				background: $invalid-color;
			}
		}

		&-inner {
			width: 100%;
			padding-top: $column-width;
			padding-right: $column-width;
			padding-left: $column-width;
			padding-bottom: calc(#{$column-width} + (#{$dist} * 1.5));

			@include breakpoint(null, $bp-m) {
				padding-top: $dist;
				padding-right: $dist;
				padding-left: $dist;
				padding-bottom: calc(#{$dist} * 2.5);
			}
		}

		.breadcrumbs {
			font-family: $ff-hs;
			text-transform: uppercase;
			font-weight: $fw-medium;
			color: $fc-light;
			margin-bottom: math.div($dist, 1.5);
			display: inline-block;

			a {
				color: $fc-light;
			}

			@include breakpoint(null, $bp-s) {
				margin-bottom: math.div($dist, 3);
			}
		}

		h2 {
			font-family: $ff-h;
			color: $hc;
			font-size: $fs-h2;
			font-weight: $fw-h-bold;
			margin-bottom: $dist*0.5;

			@include breakpoint(null, $bp-m) {
				font-size: $fs-h3;
			}

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h4;
			}

			&:after {
				content: "";
				height: 2px;
				width: $dist*3;
				display: block;
				margin-top: $dist*0.5;
				margin-right: auto;
				margin-left: auto;
				background: $ac;
			}
		}
	}

	&__price {
		.product-price {
			&__value {
				font-size: 40px;
				font-weight: $fw-medium;
				color: $ac;
				display: block;
				margin-bottom: $dist*0.5;

				@include breakpoint(null, $bp-s) {
					font-size: $fs-h4;
				}
			}

			&__explanation {
				font-size: $fs;
				color: $fc;
				display: block;
			}
		}
	}

	&__vat {
		font-size: $fs;
		color: $fc;
		display: block;
		margin-top: math.div($dist, 6);
	}
}

body.has-sidebar {
	@include breakpoint($bp-l, null) {
		.product-page__left {
			width: span(3 of 9);
			margin-right: gutter(9);
		}

		.product-page__right {
			width: span(6 of 9);
		}
	}

	@include breakpoint(null, $bp-l) {
		.product-page__left {
			width: span(4 of 15);
			margin-right: gutter(15);
		}

		.product-page__right {
			width: span(11 of 15);
		}
	}

	@include breakpoint(null, $bp-m) {
		.product-page__left {
			width: 100%;
			margin-right: 0;
		}

		.product-page__right {
			width: 100%;
		}
	}
}

body:not(.has-sidebar) {
	.product-page__left {
		width: span(6 of 15);
		margin-right: gutter(15);

		@include breakpoint(null, $bp-l) {
			width: span(4 of 15);
		}

		@include breakpoint(null, $bp-m) {
			margin-right: 0;
			width: 100%;
		}
	}

	.product-page__right {
		width: span(9 of 15);
		@extend %background-pattern;

		@include breakpoint(null, $bp-l) {
			width: span(11 of 15);
			height: auto !important;
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;
		}
	}
}