@use "sass:math";

.address {
	&--default-invoice,
	&--default-shipping {
		background: $box-color;
		font-size: $fs-small;
		font-weight: $fw-medium;
		border-radius: $radius;
		padding: math.div($dist, 6) $dist*0.25;
	}

	&__defaults {
		padding-bottom: $dist*0.25;
	}

	&__name,
	&__phone,
	&__company,
	&__line1 {
		&:before {
			width: auto;
			min-width: 1em;
			margin-right: 0.5em;
		}
	}

	&__name {
		font-weight: $fw-medium;

		&:before {
			@include fontawesome($fa-var-user);
		}
	}

	&__phone {
		&:before {
			@include fontawesome($fa-var-phone);
		}
	}

	&__company {
		&:before {
			@include fontawesome($fa-var-building);
		}
	}

	&__line1 {
		&:before {
			@include fontawesome($fa-var-map-marker);
		}
	}

	&__line2,
	&__zip-city,
	&__state,
	&__country {
		padding-left: 1.5em;
	}

	&__actions {
		margin-top: $dist*0.25;
	}
}

.address__actions--default-invoice.button,
.address--default-invoice,
.address__actions--default-shipping.button,
.address--default-shipping,
.address__actions--delete.button {
	&:before {
		width: auto;
		margin-right: 0.5em;
	}
}

.address__actions--default-invoice.button,
.address--default-invoice {
	&:before {
		@include fontawesome($fa-var-file-invoice);
		margin-right: 0.5em;
	}
}

.address__actions--default-shipping.button,
.address--default-shipping {
	&:before {
		@include fontawesome($fa-var-shipping-fast);
	}
}

.address__actions--delete.button {
	&:before {
		@include fontawesome($fa-var-trash-alt);
	}
}
