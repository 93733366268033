@use "sass:math";

section.teaserboxes {
	@extend %outer-container;
	@extend %cf;

	h2 {
		@extend %page-title-styling;
	}

	.teaserbox {
		width: calc((100% - #{$column-width}) / 2);
		float: left;
		text-align: center;

		&:first-of-type {
			margin-right: $column-width;
		}

		@include breakpoint(null, $bp-l) {
			width: calc((100% - #{$dist}) / 2);

			&:first-of-type {
				margin-right: $dist;
			}
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;

			&:first-of-type {
				margin-right: 0;
				margin-bottom: $dist;
			}
		}

		&-top {
			width: $dist*4;
			height: $dist*4;
			border: 2px solid $border-color;
			background: #ffffff;
			position: relative;
			margin: 0 auto;

			@include breakpoint(null, $bp-s) {
				width: $dist*3;
				height: $dist*3;
			}

			i {
				@include center(f);
				color: $ac;
				font-size: $fs-h1;

				@include breakpoint(null, $bp-s) {
					font-size: $fs-h2;
				}
			}
		}

		&-bottom {
			@extend %background-pattern;
			padding: $dist*3 $dist $dist*2 $dist;
			margin-top: -$dist*2;

			@include breakpoint(null, $bp-l) {
				padding-bottom: $dist;
			}

			@include breakpoint(null, $bp-s) {
				margin-top: -$dist*1.5;
				padding-top: $dist*2.3;
			}

			h3 {
				font-size: $fs-h4;
				color: $hc;
				font-weight: $fw-h-medium;
				margin-bottom: math.div($dist, 1.5);
				line-height: 1em;

				@include breakpoint(null, $bp-xs) {
					margin-bottom: $dist*0.5;
					font-size: $fs-h5;
				}
			}

			p {
				margin-bottom: $dist;

				@include breakpoint(null, $bp-s) {
					margin-bottom: $dist*0.5;
				}
			}

			a {
				@extend %button;
			}
		}
	}
}