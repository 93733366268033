#grasenhiller_teampage {

  .main__text {
    @include resp-margin(bottom);
  }

  .team-members {
    @extend %cf;

    .team-member {
      $grid-conf: (
        (null, $bp-m, 1),
        ($bp-m, null, 2)
      );

      @include resp-gallery-layout($grid-conf);
    }
  }
}