@use "sass:math";

.order-summary {
	h3 {
		display: block;
		font-size: $fs-h4;
		font-family: $ff;
		font-weight: $fw-h-medium;
		margin-bottom: $dist*0.5;
		color: $hc;

		@include breakpoint(null, $bp-m) {
			font-size: $fs-h5;
		}
	}

	&__status {
		margin-bottom: $dist;
		padding-bottom: $dist;
		border-bottom: 2px solid $border-color;

		ul {
			@extend %cf;
			@include resp-padding(v);
			@include resp-padding(h);
			@extend %background-pattern;

			li {
				float: left;
				width: span(6 of 12);
				margin-right: gutter(12);
				margin-top: $dist*0.25;

				&:nth-of-type(2n+2) {
					margin-right: 0;
				}

				&:nth-of-type(-n+2) {
					margin-top: 0;
				}

				i {
					margin-right: 0.5em;
				}

				span {
					&:first-of-type {
						font-weight: $fw-medium;
					}
				}
			}
		}
	}

	&__addresses {
		@extend %cf;
		padding: $dist;
		border: 2px solid $border-color;
		margin-bottom: $dist;

		@include breakpoint(null, $bp-s) {
			padding: $dist*0.5;
		}

		.order-summary__invoice-address,
		.order-summary__shipping-address {
			float: left;
			width: span(6 of 12);

			@include breakpoint(null, $bp-m) {
				width: 100%;
			}
		}

		.order-summary__shipping-address {
			border-left: 2px solid $border-color;
			padding-left: gutter(12);

			@include breakpoint(null, $bp-m) {
				padding-left: 0;
				border-left: 0;
				margin-top: $dist;
				border-top: 2px solid $border-color;
				padding-top: $dist;
			}

			@include breakpoint(null, $bp-s) {
				margin-top: $dist*0.5;
				padding-top: $dist*0.5;
			}
		}
	}

	&__payment-shipping {
		@extend %cf;
		padding: $dist;
		border: 2px solid $border-color;
		margin-bottom: $dist;

		@include breakpoint(null, $bp-s) {
			padding: $dist*0.5;
		}

		& > * {
			float: left;
			width: span(6 of 12);

			&:first-of-type:not(:last-of-type) {
				margin-right: gutter(12);
			}

			@include breakpoint(null, $bp-m) {
				width: 100%;
			}
		}
	}


	&__payment {
		border-right: 2px solid $border-color;
		h3 {
			&:before {
				@include fontawesome($fa-var-coins);
				margin-right: math.div($dist, 1.5);
				font-size: $fs-h5;

				@include breakpoint(null, $bp-s) {
					margin-right: math.div($dist, 3);
				}
			}
		}

		@include breakpoint(null, $bp-m) {
			margin-right: 0;

			&:first-of-type {
				border-bottom: 2px solid $border-color;
				border-right: 0;
				padding-bottom: $dist;
				margin-bottom: $dist;
			}
		}

		@include breakpoint(null, $bp-s) {
			&:first-of-type {
				padding-bottom: $dist*0.5;
				margin-bottom: $dist*0.5;
			}
		}
	}

	&__shipping {
		h3 {
			&:before {
				@include fontawesome($fa-var-shipping-fast);
				margin-right: math.div($dist, 1.5);
				font-size: $fs-h5;

				@include breakpoint(null, $bp-s) {
					margin-right: math.div($dist, 3);
				}
			}
		}
	}

	&__invoice-address--same-as-shipping {
		width: 100%;
	}

	&__member-note {
		margin-bottom: $dist;
		padding-bottom: $dist;
		border-bottom: 1px solid $border-color;
	}

	&__coupon-code {
		color: $fc-light;
	}

	&__line-items {
		margin-bottom: $dist;

		$widthPrice: 125px;
		$widthQuantity: 50px;
		$widthSum: 125px;
		$widthTitle: calc(100% - #{$widthPrice + $widthQuantity + $widthSum + $dist*1.5});

		& > ul {
			@extend %cf;

			&:first-of-type {
				margin-bottom: $dist*0.5;
				padding-bottom: $dist*0.5;
				border-bottom: 2px solid $border-color;

				li {
					display: block;
					float: left;
					font-weight: $fw-bold;
					margin-right: $dist*0.5;

					&:first-of-type {
						width: $widthTitle;
					}

					&:nth-of-type(2) {
						width: $widthPrice;
					}

					&:nth-of-type(3) {
						width: $widthQuantity;
					}

					&:nth-of-type(4) {
						width: $widthSum;
						margin-right: 0;
						text-align: right;
					}
				}
			}

			&:last-of-type {
				margin-top: $dist*0.5;
				padding-top: $dist*0.5;
				border-top: 2px solid $border-color;

				li {
					@extend %cf;

					& > strong {
						display: block;
						float: left;
					}

					& > span {
						display: block;
						float: right;

						& > span {
							display: block;
							text-align: right;
							@extend  %cf;

							& > span {
								display: block;
								float: left;
								text-align: right;

								&:first-of-type {
									width: 5em;
									margin-right: $dist*0.25;
								}

								&:last-of-type {
									width: $widthSum;
								}
							}
						}
					}

					&:nth-last-of-type(2) {
						&,
						strong {
							font-weight: $fw-bold;
						}
					}

					&:last-of-type {
						color: $fc-light;
						font-size: $fs-small;
					}
				}
			}
		}

		.line-item {
			@extend %cf;
			margin-bottom: $dist*0.25;
			padding-bottom: $dist*0.25;
			border-bottom: 1px dashed $border-color;

			&:nth-last-of-type(2) {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}

			li {
				display: block;
				margin-right: $dist*0.5;
				float: left;

				&:first-of-type {
					width: $widthTitle;
					font-weight: $fw-medium;
				}

				&:nth-of-type(2) {
					width: $widthPrice;
				}

				&:nth-of-type(3) {
					width: $widthQuantity;
				}

				&:nth-of-type(4) {
					width: $widthSum;
					margin-right: 0;
					text-align: right;
				}
			}
		}
	}
}

#Form_OrderSummaryForm_ShippingMethodID option[disabled] {
	display: none;
}

.order-summary__paypal-link {
	display: inline-block;
	background-image: linear-gradient(100deg, rgb(0, 112, 186), rgb(0, 48, 135));
	font-size: $fs-small;
	font-weight: $fw-medium;
	border-radius: $radius;
	padding: math.div($dist, 6) $dist*0.25;
	color: #FFFFFF;
	line-height: 1;
	&:before {
		@include fontawesome($fa-var-paypal, brands);
		margin-right: 0.25em;
	}
	&:hover {
		background-image: linear-gradient(10deg, rgb(0, 112, 186), rgb(0, 48, 135));
		color: #FFFFFF;
	}
}