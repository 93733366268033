@use "sass:math";

.shop-category {

	&__image {
		@include image-hover($fa-var-long-arrow-right);

		img {
			@include resp-img;
		}
	}

	&__content {
		border: 2px solid $border-color;
		border-top: none;
		padding: $dist*0.5 $dist math.div($dist, 1.5) $dist;

		@include breakpoint(null, $bp-l) {
			padding: $dist*0.5;
		}

		h3 {
			font-size: $fs-h4;
			font-weight: $fw-h-medium;

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h5;
				margin-bottom: math.div($dist, 3);
			}

			a {
				color: $hc;

				&:hover {
					color: $hc;
				}
			}
		}
	}
}