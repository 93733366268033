@use "sass:math";

.login-page {
	@extend %cf;

	h3 {
		font-size: $fs-h4;
		font-family: $ff;
		font-weight: $fw-h-medium;
		margin-bottom: math.div($dist, 1.5);
		color: $ff-h;
	}

	form {
		width: 100% !important;
	}

	&__login {
		float: left;
		width: span(5 of 15);
		margin-right: gutter(15);
		padding: $dist;
		@extend %background-pattern;

		@include breakpoint(null, $bp-l) {
			width: span(7 of 15);
		}

		@include breakpoint(null, 900px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: $dist;
		}

		@include breakpoint(null, $bp-s) {
			padding: math.div($dist, 1.5);
		}

		input {
			border-color: #ffffff;

			&:focus {
				border-color: $ac;
			}
		}
	}

	&__registration {
		float: left;
		width: span(10 of 15);
		padding: $dist;
		border: 2px solid $border-color;

		@include breakpoint(null, $bp-l) {
			width: span(8 of 15);
		}

		@include breakpoint(null, 900px) {
			width: 100%;
		}

		@include breakpoint(null, $bp-s) {
			padding: math.div($dist, 1.5);
		}
	}
}