.module--grid {
  .grid {
    @extend %cf;

    &__item {
      display: block;
      float: left;
    }

    &__header {
      text-align: center;

      img {
        display: inline-block;
        width: 100%;
        height: auto;
      }

      &--icon {
        width: $dist*4;
        height: $dist*4;
        border: 2px solid $border-color;
        background: #ffffff;
        position: relative;
        margin: 0 auto;

        @include breakpoint(null, $bp-s) {
          width: $dist*3;
          height: $dist*3;
        }

        i {
          @include center(f);
          color: $ac;
          font-size: $fs-h1;

          @include breakpoint(null, $bp-s) {
            font-size: $fs-h2;
          }
        }
      }

      a {
        @include image-hover($fa-var-link);
      }
    }

    &__footer {
      @extend %background-pattern;
      padding-top: $dist*0.5;
    }

    &__footer--icon {
      padding-top: $dist*2.5;
      margin-top: -$dist*2;

      @include breakpoint(null, $bp-s) {
        padding-top: $dist*2;
        margin-top: -$dist*1.5;
      }
    }

    &__title {
      margin-bottom: $dist*0.5;
      padding-left: $dist;
      padding-right: $dist;
      
      h4 {
        font-size: $fs-h4;
        color: $hc;
        font-weight: $fw-h-medium;

        @include breakpoint(null, $bp-xs) {
          font-size: $fs-h5;
        }
      }
    }

    &__content {
      padding: 0 $dist $dist $dist;
    }

    &__link {
      display: inline-block;
      margin-top: $dist*0.5;
      text-decoration: none;
    }

    &--align-center {
      text-align: center;
    }

    &--ipr-2 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, null, 2, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-3 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 3, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-4 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 4, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--as-slider {
      .grid__item {
        margin: 0 !important;
      }
    }
  }
}
