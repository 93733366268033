@use "sass:math";

.page-3 {
	main {
		@extend %cf;

		article {
			width: calc((100% - #{$column-width}) / 2);
			float: left;
			margin-right: $column-width;

			@include breakpoint(null, $bp-l) {
				width: calc((100% - #{$dist}) / 2);
				margin-right: $dist;
			}

			@include breakpoint(null, $bp-m) {
				width: 100%;
				float: none;
				margin-bottom: $dist;
			}

			p {
				@extend %background-pattern;
				padding-bottom: $dist;
				padding-left: $dist;
				padding-right: $dist;
				margin-top: 0;

				&:first-of-type {
					padding-top: $dist;
				}

				&:last-of-type {
					background: none;
					padding: 0;
				}
			}
		}

		form {
			width: calc((100% - #{$column-width}) / 2);
			clear: none;
			float: left;
			margin-top: 0;
			padding: $dist;
			border: 2px solid $border-color;

			@include breakpoint(null, $bp-l) {
				width: calc((100% - #{$dist}) / 2);
			}

			@include breakpoint(null, $bp-m) {
				width: 100%;
				float: none;
			}

			@include breakpoint(null, $bp-s) {
				padding: math.div($dist, 1.5);
			}
		}
	}
}