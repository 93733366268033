.page-media {
  @include resp-margin(top);

  &__images {
    @extend %cf;
    margin: 0;
    padding: 0;

    li {
      list-style: none !important;

      $grid-conf: (
         (null, $bp-m, 2),
         ($bp-m, $bp-l, 3),
         ($bp-l, null, 6)
       );

      @include resp-gallery-layout($grid-conf);

      a {
        display: block;
        @include image-hover();

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    & + .page-media__files {
      @include resp-margin(top);
    }
  }

  &__files {
    margin: 0;
    padding: 0;

    li {
      @extend %cf;
      list-style: none !important;
      display: block;
      border-bottom: 1px dashed $border-color;
      margin-bottom: $dist*0.25;
      padding-bottom: $dist*0.25;

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }

      a {
        display: block;
        width: calc(100% - 100px);
        float: left;
        color: $fc;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $fw-medium;
        font-size: $fs-h6;
        font-family: $ff-hs;

        &:hover {
          text-decoration: underline;
        }

        i {
          margin-right: 0.5em;
          font-size: $fs-h5;
          color: $ac;
        }
      }

      span {
        display: block;
        width: 100px;
        text-align: right;
        float: right;
        color: $fc-light;
      }
    }
  }
}