// - General
.module--logos {
	.logos {
		&--grid {
			ul {
				text-align: center;
				margin-top: -$dist;
				margin-left: -$dist;
				font-size: 0;

				li {
					display: inline-block;
					margin-top: $dist;
					margin-left: $dist;
					width: 170px;
					height: 83px;

					a {
						display: block;
					}
				}
			}
		}

		&--carousel {

		}
	}
}

// - Only outside columns or after breakpoint
& > .module--logos,
.module--logos.module--breakpoint-reached {
	.logos {
		&--carousel {

		}
	}
}

// - Only in Columns above breakpoint
@include breakpoint($bp-columns, null) {
	.modular-content-column > .module--logos {
		.logos {
			&--carousel {

			}
		}
	}
}