@use "sass:math";

html .userform {
	&-fields {
		margin-top: 0 !important;
	}

	&-progress {
		@include resp-margin(bottom);

		& > p {
			display: none;
		}

		.progress {
			background: $box-color;
			height: math.div($dist, 1.5);

			&-bar {
				background: $box-color-dark;
				height: math.div($dist, 1.5);
			}
		}

		& > nav {
			margin-top: math.div(-$dist, 1.5);

			ul {
				font-size: 0;

				li {
					font-size: $fs;
					height: math.div($dist, 1.5);

					button {
						display: block;
						border: 0;
						outline: 0;
						padding: 0;
						margin: 0;
						height: math.div($dist, 1.5);
						min-width: $dist;
						text-align: center;
						background: #ffffff;
						border-right: 1px solid #ffffff;
						border-left: 1px solid #ffffff;
						color: $fc;
					}

					&:first-of-type {
						button {
							border-left: 0 !important;
						}
					}

					&:last-of-type {
						button {
							border-right: 0 !important;
						}
					}

					&.viewed {
						button {
							cursor: pointer;
						}
					}

					&.current {
						button {

						}
					}
				}
			}
		}
	}

	.progress-title {
		@include resp-margin(v);
	}

	.FormHeading {
		margin-bottom: $dist*0.5;
	}

	.step-navigation {
		ul {
			@extend %cf;
			font-size: 0;

			li {
				font-size: $fs;

				button {
					@extend %button;
					@extend %button--neutral;

					&.step-button-prev:not([style="display: none;"]) {
						margin-right: $dist*0.25;
					}

					&.step-button-prev {

					}

					&.step-button-next {

					}
				}
			}
		}
	}

	.userformsgroup {
		margin-top: $dist*0.5;
		border: 2px solid $border-color;
		@include resp-padding(v);
		@include resp-padding(h);

		& > legend {
			padding-left: $dist*0.25;
			padding-right: $dist*0.25;
			margin-left: -$dist*0.25;
			border: 0;
			width: auto;
			font-weight: $fw-medium;
		}
	}

	.step-button-wrapper.Actions {
		margin-top: 0;
		float: right;
	}
}