@use "sass:math";

.navigation.navigation--footer {
	ul {

		li {
			line-height: $line-height-big;

			&[class*="--current"],
			&[class*="--section"] {
				a {
					color: $border-color;
				}
				&:hover {
					& > a {
						color: $border-color;
						text-decoration: underline;
					}
				}
			}

			a {
				color: $border-color;
				margin-bottom: math.div($dist, 2.5);

				&:hover {
					text-decoration: underline;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}