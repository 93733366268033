.navigation.navigation--mobile {
	ul {
		li {
			@extend %cf;

			&:hover {
				& > .navigation__sub {
					display: none;
					transform: translateX(0);
				}
			}
		}
	}

	.navigation__sub {
		clear: both;
		position: static !important;
		display: none;
		border-top: 2px solid $border-color;
		opacity: 1 !important;
		visibility: visible !important;
		transform: none !important;

		&--active {
			display: block !important;
		}

		.navigation__sub-link {
			@extend %cf;
			border-bottom: 2px solid $border-color;

			&--has-sub {
				& > a {
					float: left;
					width: calc(100% - 1em - #{$dist*0.5});
				}

				& > .navigation__sub-toggle {
					padding: $dist*0.25;
					width: calc(1em + #{$dist*0.5});
				}
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}

	.navigation__sub-toggle {
		display: block;
		float: left;
		cursor: pointer;
		color: $fc-light;
		line-height: 1;

		&:hover,
		&--active {
			color: $fc;
		}

		&--active {
			i:before {
				content: fa-content($fa-var-minus-circle);
			}
		}
	}

	// - mobile navigation styling | level 1
	.navigation__top-link {
		border-bottom: 2px solid $border-color;
		font-family: $ff-hs;
		text-transform: uppercase;
		font-size: $fs-h6;
		font-weight: $fw-medium;

		& > a {
			padding: $dist*0.5;
		}

		&--has-sub {
			& > a {
				float: left;
				width: calc(100% - 1em - #{$dist*0.5});
			}
		}

		& > .navigation__sub-toggle {
			padding: $dist*0.5 $dist*0.25;
			width: calc(1em + #{$dist*0.5});
		}

		// - level 2
		& > .navigation__sub {
			a {
				padding: $dist*0.25 $dist*0.5;
			}

			& > li {
				// - level 3
				& > .navigation__sub {
					& > li {
						& > a {
							background: darken($page-bg, 5%);
						}

						& > .navigation__sub-toggle {
							background: darken($page-bg, 5%);
						}

						// - level 4
						& > .navigation__sub {
							& > li {
								& > a {
									background: darken($page-bg, 10%);
								}
							}
						}
					}
				}
			}
		}
	}
}