.news-preview {
  @extend %cf;
  @include resp-margin(bottom);
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__text {
    @extend %background-pattern;
    padding: $dist*1.5 $dist $dist*1.75 $dist*4;
    border-left: 2px solid $ac;
  }

  &__image {
    a {
      @include image-hover($fa-var-link);
    }
  }

  @include breakpoint($bp-l, null) {
    &__image {
      width: 534px;
      position: absolute;
    }

    &__text {
      width: calc(100% - (534px - (#{$dist} * 3)));
      float: right;
    }
  }

  @include breakpoint(600px, $bp-l) {
    &__image{
      width: 296px;
      position: absolute;
    }

    &__text {
      width: calc(100% - (296px - (#{$dist} * 3)));
      float: right;
      padding-top: $dist;
    }
  }

  @include breakpoint($bp-s, 600px) {
    &__image{
      width: 200px;
      position: absolute;
    }

    &__text {
      width: calc(100% - (200px - (#{$dist} * 3)));
      float: right;
      padding-top: $dist;
    }
  }

  @include breakpoint(null, $bp-s) {
    &__image{
      width: 100%;
      position: static;
      margin-bottom: 0;
    }

    &__text {
      width: 100%;
      float: none;
      padding-top: $dist;
      padding-left: $dist;
      padding-bottom: $dist;
    }
  }

  &__image {
    a {
      display: block;

      &:hover {
        text-decoration: none;
        border-bottom: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__text {
    & > h2 {
      margin-bottom: 0;
      font-family: $ff;
      font-size: $fs-h4;
      font-weight: $fw-h-medium;

      @include breakpoint(null, $bp-xs) {
        font-size: $fs-h5;
      }

      a {
        color: $hc !important;
        background: none !important;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    & > small {
      display: block;
      font-family: $ff-hs;
      text-transform: uppercase;
      font-weight: $fw-medium;
      color: $fc-light;
    }

    & > p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .button {
      margin-top: $dist;

      &:hover {
        color: #ffffff;
      }
    }
  }
}