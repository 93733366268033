// - Susy Grid
@use "sass:math";

$max-width: 1380px;
$column-width: 64px;
$gutter-width: 30px;
$columns: 15;

// - Sizes
$dist: $gutter-width;
$mobilenav-width: 270px;
$radius: 4px;
$duration: 0.2s;
$sidebarWidthInPx: 292px;

// - Text & Highlight Colors
$fc: #535353;
$fc-light: #7e7e7e;
$hc: #543d35;
$hc-hover: darken($hc, 5%);
$ac: #9ea737;
$ac-hover: darken($ac, 5%);
$ac-sec: #8d9532;
$ac-sec-hover: darken($ac-sec, 5%);

// - Border & Box Colors
$box-color: #efefef;
$box-color-dark: darken($box-color, 10%);
$border-color: #efefef;
$border-color-dark: darken($border-color, 10%);
$page-bg: #ffffff;
$footer-bg: #7e7e7e;
$footer-bg-dark: #535353;

// - Misc
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-hover: rgba(0, 0, 0, 0.35);
$valid-color: #82BD35;
$warning-color: #EBB61A;
$invalid-color: #F24D44;

// - Font Family
$ff: 'amplitude', 'Barlow', sans-serif;
$ff-hs: 'amplitude-condensed', 'Barlow Semi Condensed', sans-serif;
$ff-h: 'merriweather', 'Barlow', serif;

// - Font Sizes
$line-height-int: 1.5;
$line-height-big-int: 1.75;
$fs-root-int: 18;
$fs-int: 18;
$fs-small-int: 14;
$fs-h1-int: 55;
$fs-h2-int: 44;
$fs-h3-int: 36;
$fs-h4-int: 28;
$fs-h5-int: 22;
$fs-h6-int: 20;

// - Font Weights
$fw: 300;
$fw-light: $fw;
$fw-medium: 500;
$fw-bold: 700;
$fw-h-light: $fw;
$fw-h: $fw;
$fw-h-medium: 500;
$fw-h-bold: 700;

// - Breakpoints
$bp-xs: 350px;
$bp-s: 480px;
$bp-m: 768px;
$bp-l: 1036px;
$bp-xl: $max-width + $dist*2;
$bp-nav: 920px;

// ------
// - You don't need to touch those variables below.
// - They are automatically configured with the values above
// ------

$susy: (
	'columns': susy-repeat($columns),
	'gutters': math.div($gutter-width, $column-width),
	'spread': 'narrow',
	'container-spread': 'narrow',
);

$line-height: $line-height-int + em;
$line-height-big: $line-height-big-int + em;
$fs-root: $fs-root-int + px;
$fs-small: math.div($fs-small-int, $fs-root-int) + rem;
$fs: math.div($fs-int, $fs-root-int) + rem;
$fs-h1: math.div($fs-h1-int, $fs-root-int) + rem;
$fs-h2: math.div($fs-h2-int, $fs-root-int) + rem;
$fs-h3: math.div($fs-h3-int, $fs-root-int) + rem;
$fs-h4: math.div($fs-h4-int, $fs-root-int) + rem;
$fs-h5: math.div($fs-h5-int, $fs-root-int) + rem;
$fs-h6: math.div($fs-h6-int, $fs-root-int) + rem;
