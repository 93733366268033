@use "sass:math";

html {
	font-size: $fs-root;

	@include breakpoint(null, $bp-s) {
		font-size: calc(#{$fs-root} - 2px);
	}
}

body {
	font-family: $ff;
	font-weight: $fw;
	font-size: $fs;
	color: $fc;
	text-size-adjust: none;
}

// - Bold elements
strong, b, th {
	font-weight: $fw-medium;
}

h1, h2, h3 {
	font-weight: $fw-h-bold;
	color: $hc;
}

h4, h5, h6 {
	font-weight: $fw-h-medium;
	color: $hc;
	font-family: $ff;
}

// - Small text
small {
	font-size: $fs-small;
}

// - Lineheight
h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, li {
	line-height: $line-height;
}

// - Icons
i.fa,
i.fal,
i.far,
i.fab {
	display: inline-block;
	min-width: 1em;
	text-align: center;
	font-style: normal;
}

// - Links
a {
	color: $fc;
	text-decoration: none;

	&:hover {
		color: $ac;
	}
}

// - Text Selection
::selection {
	color: #ffffff;
	background: $hc;
}

::-moz-selection {
	color: #ffffff;
	background: $hc;
}

// - Horizontal lines
hr {
	border: none;
	height: 1px;
	background: $border-color;
	@include resp-margin(v);
}

// - Italic
em, i {
	font-style: italic;
}

// - Messages
.system-message {
	& + * {
		margin-top: $dist !important;

		@include breakpoint(null, $bp-m) {
			margin-top: $dist*0.5 !important;
		}
	}

	& + .system-message {
		margin-top: math.div($dist, 6) !important;
	}

	&,
	&--neutral {
		cursor: pointer;
		position: relative;
		border-left: 5px solid $border-color;
		padding: $dist*0.5 $dist $dist*0.5 $dist*0.5;
		background: rgba($border-color, 0.1);
	}

	&--good {
		border-color: $valid-color;
		background: rgba($valid-color, 0.1);
	}

	&--warning {
		border-color: $warning-color;
		background: rgba($warning-color, 0.1);
	}

	&--bad {
		border-color: $invalid-color;
		background: rgba($invalid-color, 0.1);
	}

	&--permanent {
		cursor: default;
	}
}

.message:not([style*="display: none"]) {
	@extend .system-message;
	@extend .system-message--permanent;

	&.bad,
	&.error,
	&.required {
		@extend .system-message--bad;
	}

	&.good,
	&.success {
		@extend .system-message--good;
	}

	&.warning {
		@extend .system-message--warning;
	}
}

// - Distance
.dist-right {
	margin-right: 0.25em;
}

.dist-left {
	margin-left: 0.25em;
}

.dist-top {
	margin-top: 0.25em;
}

.dist-bottom {
	margin-bottom: 0.25em;
}

br {
	line-height: 1;
};

sup {
	vertical-align: super;
	font-size: smaller;
}

sub {
	vertical-align: sub;
	font-size: smaller;
}

textarea {
	resize: vertical;
}

.global-sc-dist-right {
	margin-right: 0.5em;
}

.global-sc-dist-left {
	margin-left: 0.5em;
}

.global-sc-dist-top {
	margin-top: 0.5em;
}

.global-sc-dist-bottom {
	margin-bottom: 0.5em;
}