@use "sass:math";

$sidebarWidthInPx: 270px;

.has-sidebar {
	.outer-wrapper {
		@extend %cf;
	}

	.main {
		float: left;
		width: span(11 of 15)
	}

	.sidebar {
		@extend %cf;
		float: right;
		margin-left: gutter(15);
		width: span(4 of 15);
	}

	@include breakpoint($bp-l, null) {
		.sidebar + * {
			padding-top: $dist;
			clear: both;
		}
	}

	@include breakpoint(null, $bp-l) {
		.main,
		.sidebar {
			float: none;
			width: 100%;
		}

		.sidebar {
			margin-left: 0;
			margin-top: $dist;
		}
	}

	@include breakpoint($bp-m - $dist*4, $bp-l) {
		.s-widget {
			width: span(6 of 12);
			float: left;

			&:nth-of-type(2n+2) {
				margin-left: gutter(12);
			}

			&:nth-of-type(2n+3) {
				clear: both;
			}
		}
	}
}
.s-widget--accountnavigation {
	border: 2px solid $border-color;
	border-left-color: $ac !important;

	ul {

		li {
			padding-left: 0 !important;
			&:before {
				display: none !important;
			}
			a {
				i {
					margin-right: math.div($dist, 3);
					width: $fs;
				}
			}
		}
	}
}

.s-widget {
	border: 2px solid $border-color;
	margin-bottom: $dist;
	padding: math.div($dist, 1.5) $dist;

	&:last-of-type {
		margin-bottom: 0;
	}

	&__inner {
		font-size: 1rem;
	}

	&__title {
		margin-bottom: math.div($dist, 3);

		strong {
			font-size: $fs-h5;
			font-family: $ff;
			font-weight: $fw-h-medium;
			color: $hc;
		}
	}

	&__nav {
		ul {
			li {

				&:before {
					@include fontawesome($fa-var-long-arrow-right);
					padding-right: math.div($dist, 3);
					width: math.div($dist, 1.25);
				}

				a {
					color: $fc;
					text-decoration: none;
					text-transform: uppercase;
					font-family: $ff-hs;
					font-weight: $fw-medium;
					font-size: $fs-h6;
				}

				&:hover {
					a {
						text-decoration: underline;
						color: $ac;
					}

					&:before {
						color: $ac;
					}
				}
			}
		}
	}

	&__text {
		.global-sc-dist-right + a {
			color: $fc;
			text-decoration: none;

			&:hover {
				color: $ac;
			}
		}
	}

	&__images {
		li {
			margin-bottom: math.div($dist, 1.5);

			img {
				@include resp-img;
				margin: 0 auto;
			}

			a {
				display: block;
				margin: 0 auto;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}