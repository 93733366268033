@use "sass:math";

.navigation.navigation--main {

	.navigation__top-link {
		font-size: $fs-h5;
		text-transform: uppercase;

		a {
			color: $hc;
			font-family: $ff-hs;
			font-weight: $fw-medium;
		}
	}

	&.navigation--horizontal {
		.navigation__top-link {
			padding-bottom: 13px;

			&:hover {
				& > .navigation__sub {
					margin-top: $dist*0.5;
					background: #ffffff;
					padding: $dist*0.5 math.div($dist, 1.5);
					border: 2px solid $border-color;

					li {
						border-bottom: 2px solid $border-color;
						padding-bottom: $dist*0.5;
						margin-bottom: $dist*0.5;

						&:last-of-type {
							border-bottom: none;
							padding-bottom: 0;
							margin-bottom: 0;
						}
					}
				}
			}

			& > .navigation__sub {
				a {
					color: $hc;

					&:hover {
						color: $ac
					}
				}
			}

			a {
				&:hover {
					color: $ac;
					text-decoration: underline;
				}
			}

			&:after {

			}
		}
	}

	@include breakpoint(null, $bp-nav) {
		.mobile-navigation-button {
			display: block;
		}

		.navigation__top {
			display: none;
		}
	}

	// - mobile nav button
	.mobile-navigation-button {
		display: inline-block;
		cursor: pointer;
		font-size: 32px;
		color: $fc;

		&:hover {
			color: $ac;
		}

		@include breakpoint($bp-nav, null) {
			display: none;
		}
	}
}