@use "sass:math";

.module--downloads {
  ul {
    margin: 0;
    padding: 0;

    li {
      @extend %cf;
      list-style: none !important;
      display: block;
      border: 2px solid $border-color;
      padding: math.div($dist, 1.5);
      margin-bottom: $dist*0.25;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        display: block;
        width: calc(100% - 100px);
        float: left;
        color: $fc;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $fw-medium;
        font-size: $fs-h6;
        font-family: $ff-hs;


        &:hover {
          text-decoration: underline;
        }

        i {
          margin-right: 0.5em;
          font-size: $fs-h5;
          color: $ac;
        }
      }

      span {
        display: block;
        width: 100px;
        text-align: right;
        float: right;
        color: $fc-light;
      }
    }
  }
}