@use "sass:math";

.team-members,
.search-results__list--TeamMember .search-results__list-items {
  .team-member {
    @extend %cf;

    &__image {
      position: relative;
      float: left;
      margin-right: $dist;
      width: 35%;
      background: $box-color;
      text-align: center;

      @include breakpoint($bp-m, $bp-l) {
        margin-right: 0;
        width: 30%;
      }

      @include breakpoint(null, 600px) {
        width: 30%;
        margin-right: $dist*0.5;
      }

      img {
        @include resp-img;
      }

      &--no-image {
        @include aspect-ratio(235,314);

        & > *:first-child {
          font-size: 2.5rem;
          color: $box-color;
          @include center(f);
          right: initial;
          bottom: initial;
        }
      }
    }

    &__info {
      float: left;
      width: calc(100% - (35% + #{$dist}));
      margin-top: $dist;
      border-top: 2px solid $border-color;
      border-right: 2px solid $border-color;
      border-bottom: 2px solid $border-color;
      border-left: 2px solid $ac;
      padding: $dist;

      @include breakpoint($bp-m, $bp-l) {
        width: calc(100% - 30%);
        margin-top: 0;
      }

      @include breakpoint(null, 600px) {
        width: calc(100% - (30% + (#{$dist} / 2)));
        margin-top: 0;
        padding: math.div($dist, 1.5);
      }

      i {
        margin-right: 0.5em;
        color: $ac;
      }

      a {
        @extend %decent-link;
        font-weight: $fw-light;
      }
    }

    &__separator {
      line-height: 1;
      height: $dist*0.5;
    }

    &__title {
      font-weight: $fw-medium;
      font-size: $fs-h5;
      color: $hc;

      @include breakpoint(null, $bp-s) {
        font-size: $fs-h6;
      }
    }
  }
}