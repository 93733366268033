.secure-files {
	@extend %cf;

	#Form_FilesRegisterForm_GDPR_Holder {
		label {
			line-height: $line-height;
		}
	}

	&__left {
		@include breakpoint($bp-m, null) {
			float: left;
			width: span(6 of 12);
			margin-left: gutter(12);
		}

		background: $box-color;
		@include resp-padding(v);
		@include resp-padding(h);

		fieldset {
			input,
			select,
			textarea {
				background: #ffffff;
			}
		}
	}

	&__right {
		@include breakpoint($bp-m, null) {
			float: left;
			width: span(6 of 12);
		}

		& > strong {
			display: block;
			margin-bottom: $dist*0.25;
		}

		.formatted-text {
			@include resp-margin(bottom);
			@include resp-padding(bottom);
			border-bottom: 1px solid $border-color;
		}

		@include breakpoint(null, $bp-m) {
			margin-bottom: $dist;
		}
	}
}

.module--securefiles.module--bg-light {
	.secure-files__left {
		background: #ffffff;
	}
}

.module--securefiles.module--bg-dark {
	.secure-files__left {
		background: #ffffff;
	}

	.secure-files__right {
		& > strong {
			color: #ffffff;
		}

		& > ul {
			li {
				color: #ffffff;
			}
		}
	}
}