.shop-categories {
	@extend %cf;
}

body:not(.has-sidebar) {
	.shop-categories {
		.shop-category {
			$gridConf: (
				(null, $bp-s, 1),
				($bp-s, $bp-m, 2),
				($bp-m, null, 3)
			);

			@include resp-gallery-layout($gridConf);
		}
	}
}

body.has-sidebar {
	.shop-categories {
		.shop-category {
			$gridConf: (
				(null, $bp-s, 1),
				($bp-s, $bp-l, 2),
				($bp-l, null, 3),
			);


			@include resp-gallery-layout($gridConf);
		}
	}
}