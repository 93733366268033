.pagination {
	text-align: center;
	padding-top: $dist*2;

	@include breakpoint(null, $bp-m) {
		padding-top: $dist*1.5;
	}

	@include breakpoint(null, $bp-s) {
		padding-top: $dist;
	}

	&:before {
		display: block;
		content: '';
		border-top: 2px solid $border-color;
		margin-bottom: $dist*2;

		@include breakpoint(null, $bp-m) {
			margin-bottom: $dist*1.5;
		}

		@include breakpoint(null, $bp-s) {
			margin-bottom: $dist;
		}
	}

	a,
	span {
		display: inline-block;
		width: $dist*1.5;
		height: $dist*1.5;
		line-height: $dist*1.5;
		background: $box-color;
		font-size: $fs-h6;
		font-family: $ff-hs;
		text-transform: uppercase;
		position: relative;
		margin-right: $dist*0.5;
		font-weight: $fw-medium;

		&:after {
			content: "";
			border: 1px solid $fc;
			display: block;
			position: absolute;
			top: -3px;
			left: -3px;
			right: -3px;
			bottom: -3px;
		}
	}

	a {
		color: $fc;
		text-decoration: none;
		transition: all $duration;

		&:hover {
			background: $box-color-dark;
		}
	}

	span.pagination__current {
		background: $ac;
		color: #FFFFFF;

		&:after {
			border-color: $ac;
		}
	}
}