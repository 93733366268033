@use "sass:math";

.page-slider {

  &__slide {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__caption {
    z-index: 10;
    width: 100%;

    @include breakpoint(810px, null) {
      @include center(v);
      left: 0;
    }
  }

  &__caption-wrapper {
    max-width: $max-width;
    margin: 0 auto;
    @extend %cf;
    position: relative;

    @include breakpoint(null, 810px) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &__caption-content {
    background: rgba(84,61,53,0.6);
    width: 70%;
    float: left;
    position: relative;
    padding: $dist*2.5;

    @include breakpoint(null, $bp-xl) {
      padding: $dist*1.5
    }

    @include breakpoint($bp-l, 810px) {
      @include center(f);
      text-align: center;
      padding: $dist $dist $dist*1.5 $dist;
    }

    @include breakpoint(null, 810px) {
      text-align: center;
      padding: $dist*0.5 $dist*0.5 $dist*1.5 $dist*0.5;
      top: $dist;
      right: $dist;
      left: $dist;
      bottom: $dist*1.5;
      transform: none;
      position: absolute;
      width: calc(100% - (#{$dist} * 2));
    }

    @include breakpoint(null, $bp-s) {
      display: none;
    }

    & > h3 {
      font-size: $fs-h1;
      font-weight: $fw-h-bold;
      font-family: $ff-h;
      color: #ffffff;

      @include breakpoint(null, $bp-xl) {
        font-size: $fs-h2;
      }

      @include breakpoint(null, $bp-l) {
        font-size: $fs-h3;
      }

      @include breakpoint(null, 810px) {
        font-size: $fs-h4;
      }
    }

    & > p {
      margin-top: $dist;
      font-size: $fs-h5;
      font-weight: $fw;
      color: #ffffff;
      max-width: math.div($max-width, 1.5);

      @include breakpoint(null, $bp-xl) {
        margin-top: $dist*0.5;
      }

      @include breakpoint(null, $bp-l) {
        font-size: $fs;
      }

      @include breakpoint(null, 810px) {
        margin-top: 0;
      }

      @include breakpoint(null, 630px) {
        display: none;
      }

      span {
        box-decoration-break: clone;
      }
    }

    &--link {
      @include center(v);
      right: -$dist;

      & > a {
        font-size: 0;
        width: $dist*2;
        height: $dist*2;
        display: block;
        position: relative;
        border: 1px solid #ffffff;
        text-align: center;
        transition: $duration;

        &:after {
          @include fontawesome($fa-var-long-arrow-right, $style: regular);
          color: $hc;
          font-size: $fs-h5;
          width: 54px;
          padding: 16px 0;
          background: #ffffff;
          display: inline;
          @include center(f);
          transition: $duration;
        }

        &:hover {
          border-color: $box-color;

          &:after {
            background: $box-color;
          }
        }
      }

      @include breakpoint(null, 810px) {
        @include center(h);
        right: auto!important;
        top: auto !important;
        bottom: -$dist;
      }
    }

    &.page-slider__caption-content--font-color-light {
      & > h3,
      & > p,
      & > a {
        color: #ffffff;
      }
    }
  }

  &__nav {
    width: 100%;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    @extend %cf;
    margin-top: -3px;

    @include breakpoint(null, 810px) {
      margin-left: $dist;
      margin-right: $dist;
      width: calc(100% - (#{$dist}) * 2);
    }

    @include breakpoint(null, $bp-s) {
      margin-left: $dist*0.5;
      margin-right: $dist*0.5;
      width: calc(100% - #{$dist});
    }

    @include breakpoint(null, $bp-xs) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    &-inner {
      @extend %background-pattern;
      @extend %cf;
      padding: math.div($dist, 1.5) $dist*1.3;
      float: left;

      @include breakpoint(null, 810px) {
        float: none;
        width: 100%;
      }

      @include breakpoint(null, $bp-s) {
        padding: $dist*0.5;
      }

      i {
        font-size: $fs-h5;
        font-weight: $fw-medium;
        cursor: pointer;

        &:first-of-type {
          float: left;
        }

        &:last-of-type {
          float: right;
        }
      }

      .slick-dots {
        float: left;
        margin: -9px $dist*1.5 0 $dist*1.5;

        @include breakpoint(null, 810px) {
          text-align: center;
          float: none;
        }

        li {
          display: inline-block;
          margin-right: $dist;

          &:last-of-type {
            margin-right: 0;

            &:first-of-type {
              display: none;
            }
          }

          button {
            cursor: pointer;
            display: block;
            width: 14px;
            height: 14px;
            background: $footer-bg-dark;
            border-radius: 100%;
            font-size: 0;
            border: 0;
            box-shadow: none;
            transition: $duration;

            &:after {
              content: "";
              width: $dist;
              height: $dist;
              display: block;
              border-radius: 100%;
              margin: -10px -14px;
            }

            &:hover {
              background: darken($footer-bg-dark, 10%);
            }
          }

          &.slick-active {
            button {
              background: $footer-bg-dark;
              transition: $duration;

              &:after {
                content: "";
                border: 2px solid $footer-bg-dark;
              }

              &:hover {
                background: darken($footer-bg-dark, 10%);
              }
            }
          }
        }
      }
    }
  }
}