@use "sass:math";

.footer {
	border-top: math.div($dist, 3) solid $footer-bg;
	color: $border-color;

	&__top {
		background: $footer-bg-dark;
		padding: $dist*2 0;

		@include breakpoint(null, $bp-m) {
			padding: $dist 0;
		}

		&-inner {
			@extend %outer-container;
			@extend %cf;

			.left {
				float: left;
				width: calc((100% / 15) * 4);
				border-right: 2px solid $footer-bg;
				margin-right: calc(100% / 15);
				padding-right: calc((#{$dist} * 2) - 1px);

				img {
					max-width: 250px;
					width: 100%;
					height: auto;

					@include breakpoint(null, $bp-m) {
						max-width: 200px;
					}
				}

				@include breakpoint(null, 900px) {
					width: 100%;
					float: none;
					border-bottom: 2px solid $footer-bg;
					padding-right: 0;
					margin-right: 0;
					text-align: center;
					border-right: none;
					padding-bottom: $dist;
					margin-bottom: $dist;
				}

				@include breakpoint(null, $bp-s) {
					padding-bottom: math.div($dist, 1.5);
					margin-bottom: math.div($dist, 1.5);
				}
			}

			.right {
				float: right;
				width: calc((100% / 15) * 10);

				@include breakpoint(null, 900px) {
					width: 100%;
					float: none;
				}

				$grid-conf: (
					(null, $bp-s, 1),
					($bp-s, $bp-m, 2),
					($bp-m, null, 3)
				);

				&-column {
					@include resp-gallery-layout($grid-conf);
					@include breakpoint(null, $bp-s) {
						border-bottom: 2px solid $footer-bg;
						padding-bottom: math.div($dist, 1.5);
						margin-bottom: math.div($dist, 1.5);
						margin-top: 0;

						&:last-of-type {
							margin-bottom: 0;
							border-bottom: none;
							padding-bottom: 0;
						}
					}

					span {
						font-weight: $fw-medium;
						margin-bottom: math.div($dist, 1.5);
						display: inline-block;

						@include breakpoint(null, $bp-s) {
							margin-bottom: math.div($dist, 3);
						}
					}

					&:last-of-type {
						ul {
							li {
								display: inline;
								margin-right: $dist*0.5;

								i {
									font-size: $fs-h4;
									color: $border-color;
									font-weight: 300;
								}
							}
						}
					}
				}
			}
		}
	}

	&__bottom {
		background: $footer-bg;
		padding: math.div($dist, 1.5) 0;

		&-inner {
			@extend %outer-container;
			@extend %cf;

			@include breakpoint(null, $bp-s) {
				text-align: center;
			}

			span {
				float: left;
				font-size: $fs-small;
				line-height: 1em;
				color: $border-color;

				@include breakpoint(null, $bp-s) {
					width: 100%;
					float: none;
				}
			}

			nav {
				float: right;

				@include breakpoint(null, $bp-s) {
					width: 100%;
					float: none;
					margin-bottom: math.div($dist, 3);
					display: block;

					li {
						margin-right: 0 !important;
					}
				}

				li {
					margin-right: $dist*0.5;

					a {
						font-size: $fs-small;
						color: $border-color;
						margin-bottom: 0 !important;
					}

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}
 }