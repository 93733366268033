@use "sass:math";

.shop-product-instock {
	span {
		display: inline-block;
		font-size: $fs-small;
		text-transform: uppercase;
		font-weight: $fw-medium;
		line-height: 1;
		padding: math.div($dist, 6) $dist*0.25;
		color: #FFFFFF;
		font-family: $ff-hs;
	}

	&--out span {
		background: rgba($invalid-color, 0.8);
	}

	&--soon span {
		background: rgba($warning-color, 0.8);
	}

	&--in span {
		background: rgba($valid-color, 0.8);
	}
}