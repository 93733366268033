@use "sass:math";

.module--accordion {
  .accordion {
    &__item {
      border: 2px solid $border-color;
      padding: 2px;
      margin-bottom: $dist*0.25;

      &:last-of-type {
        margin-bottom: 0;
      }

      &--open {
        .accordion__content {
          display: block;
        }

        .accordion__title {
          @extend %background-pattern;
        }
      }
    }

    &__title {
      cursor: pointer;
      font-family: $ff-hs;
      text-transform: uppercase;
      font-size: $fs-h6;
      padding: math.div($dist, 1.5);
      @extend %cf;

      &:hover {
        @extend %background-pattern;

        i {
          color: $ac-hover;
        }
      }

      strong {
        display: block;
        float: left;
        margin-right: $dist*0.25;
        width: calc(100% - #{$dist*0.25} - #{$fs-h6});
      }

      i {
        display: block;
        float: right;
        color: $ac;
        font-size: $fs-h6;
        width: $fs;
        text-align: center;
        margin-top: $dist*0.1;
      }
    }

    &__content {
      padding: $dist*0.5;
      display: none;
    }
  }
}