#Form_CheckoutForm {
	.btn-toolbar {
		@extend %cf;
	}

	#Form_CheckoutForm_action_goBackToEdit {
		@extend %button;
		@extend %button--neutral;
		float: left;

		@include breakpoint(null, 600px) {
			width: 100%;
			margin-bottom: $dist*0.5;
		}
	}

	#Form_CheckoutForm_action_doCheckout {
		float: right;

		@include breakpoint(null, 600px) {
			width: 100%;
		}
	}
}

#Form_RegistrationForm_AcceptDataProcessing_Holder,
#Form_CheckoutForm_AcceptDataProcessing_Holder {
	position: relative;
	border-top: 1px solid $border-color;
	padding-top: $dist;

	// pre load checked checkbox to prevent flickering
	&:after {
		@include fontawesome($fa-var-check-square, solid);
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 0;
		right: 0;
		height: 1px;
		width: 1px;
	}

	input {
		position: absolute;
		top: $dist;
		left: -0.05em;
		display: block;
		opacity: 0 !important;
	}

	label {
		margin-top: 0;
		color: $fc-light;
		font-weight: $fw !important;
		text-transform: none;
		font-size: $fs;
		padding-left: 1.5em;
		@extend %formatted-text;
		font-family: $ff;

		p {
			margin-top: 0;

			&,
			& * {
				line-height: 1;
			}
		}

		&:before {
			color: $fc;
			@include fontawesome($fa-var-square, light);
			margin-left: -1.5em;
			margin-right: 0;

			display: block;
			float: left;
		}
	}

	input {
		&:checked + label:before {
			@include fontawesome($fa-var-check-square, solid);
		}
	}
}

#Form_RegistrationForm_AcceptDataProcessing_Holder {
	position: relative;
	border-top: 0;
	padding-top: 0;
}