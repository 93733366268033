#grasenhiller_homepage{
	.outer-wrapper {
		max-width: 100%;
		width: 100%;

		.homepage-content {
			@extend %outer-container;
			margin-bottom: $dist*2;

			@include breakpoint(null, $bp-m) {
				margin-bottom: $dist*1.5;
			}
		}

		.modular-content {
			margin-top: $dist*3;
			@extend %outer-container;

			@include breakpoint(null, $bp-m) {
				margin-top: $dist*1.5;
			}

			.module--textimage {
				.module__title {
					text-align: center;
					h3 {
						@extend %page-title-styling;
					}
				}
			}
		}
	}
}