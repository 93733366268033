@use "sass:math";

.module--pricetables {
	.price-tables-switch {
		//background: $box-color;  // uncomment to use toggle button
		//@include resp-padding(v);  // uncomment to use toggle button
		//@include resp-padding(h);  // uncomment to use toggle button
		margin-bottom: calc(#{$dist} + #{0.9375rem});
		font-size: 0;
		text-align: center;

		span {
			display: inline-block;
			font-size: $fs;
			vertical-align: middle;
			cursor: pointer;
			line-height: 1.2rem;

			&:first-of-type,
			&:last-of-type {
				padding-left: 0.5em; // remove to use toggle button
				padding-right: 0.5em; // remove to use toggle button
				padding-bottom: 0.25em;
				font-weight: $fw-bold;
				transition: color $duration linear;

				&:hover {
					color: $ac;
				}
			}

			&.active {
				color: $ac;
				border-bottom: 1px solid $ac;
				margin-bottom: -1px;
			}

			&:first-of-type {
				margin-right: $dist*0.5;
			}
		}

		&__toggle {
			display: none !important; // remove to use toggle button
			overflow: hidden;
			width: $column-width;
			height: $dist;
			border: 2px solid #ffffff;
			border-radius: $dist*0.5;
			margin: 0 $dist*0.5;
			box-shadow: 0 0 0 1px $border-color;

			&:before {
				content: ' ';
				display: block;
				height: $dist - 4px;
				width: $dist - 4px;
				border-radius: 100%;
				background: #ffffff;
				border: 7px solid $ac;
			}

			&[type-active="monthly"] {
				&:before {
					float: left;
				}
			}

			&[type-active="yearly"] {
				&:before {
					float: right;
				}
			}
		}
	}

	.price-tables {
		&--3 {
			.price-table {
				width: span(4 of 12);

				@include breakpoint($bp-s + $dist*2, $bp-m) {
					width: span(6 of 12);

					&:nth-of-type(2) {
						margin-right: 0;
					}

					&:nth-of-type(3) {
						clear: both;
						float: none;
						margin-left: auto !important;
						margin-right: auto !important;

						&:before {
							content: ' ';
							display: block;
							background: #ffffff;
							height: $dist;
							width: 100%;
							outline: 1px solid #ffffff;
						}
					}
				}

				@include breakpoint(null, $bp-s + $dist*2) {
					width: 100%;
					margin-right: 0;
					margin-top: $dist;

					&:nth-of-type(1) {
						margin-top: 0;
					}
				}
			}
		}

		&--4 {
			.price-table {
				width: span(3 of 12);

				@include breakpoint($bp-m, $max-width + $dist) {
					width: span(4 of 12);

					&:nth-of-type(3) {
						margin-right: 0;
					}

					&:nth-of-type(4) {
						clear: both;
						float: none;
						margin-left: auto !important;
						margin-right: auto !important;

						&:before {
							content: ' ';
							display: block;
							background: #ffffff;
							height: $dist;
							width: 100%;
							outline: 1px solid #ffffff;
						}
					}
				}

				@include breakpoint($bp-s + $dist*2, $bp-m) {
					width: span(6 of 12);

					&:nth-of-type(2) {
						margin-right: 0;
					}

					&:nth-of-type(3),
					&:nth-of-type(4) {
						margin-top: $dist;
					}
				}

				@include breakpoint(null, $bp-s + $dist*2) {
					width: 100%;
					margin-right: 0;
					margin-top: $dist;

					&:nth-of-type(1) {
						margin-top: 0;
					}
				}
			}
		}

		.price-table {
			float: left;
			margin-right: gutter(12);
			border: 1px solid $border-color;
			background: $box-color;
			position: relative;
			margin-bottom: calc((#{$line-height} + #{math.div($dist, 2.5) * 2}) / 2);

			&--inactive {
				opacity: 0.55;

				.price-table__link {
					a {
						cursor: not-allowed !important;

						&:hover {
							background: $ac !important;
						}
					}
				}
			}

			@include breakpoint(null, $bp-m) {
				&.price-table--with-badge {
					.price-table__header {
						padding-top: $dist;
					}
				}
			}

			&--no-link {
				margin-bottom: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}

			&__header {
				position: relative;
				@include resp-padding(v);
				@include resp-padding(h);
				text-align: center;
				color: #ffffff;

				h4 {
					font-size: $fs-h2;
					margin-top: -0.25em;
				}

				span {
					display: block;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					padding: 0.5em 0.75em;
					font-size: $fs-small;
					font-weight: $fw-bold;
					line-height: 1;
				}
			}

			&__price {
				border-bottom: 1px solid $border-color;
				@include resp-padding(v);
				@include resp-padding(h);
				text-align: center;
				background: #ffffff;

				strong {
					display: block;
					font-size: $fs-h2;
					margin-top: -0.25em;
				}
				
				span {
					display: block;
				}
			}

			&__content {
				@include resp-padding(v);
				@include resp-padding(h);
				background: #ffffff;

				&-top-description {
					font-weight: $fw-bold;
					line-height: $line-height;
					color: $hc;
					//background: $box-color;
					//padding: $dist/2 0;
					padding-top: math.div($dist, 1.75);
					padding-top: calc(#{$dist*0.5} + 0.4em);
					//border-bottom: 1px solid $border-color;
					@include resp-margin(h, -1);
					@include resp-margin(top, -1);
					//@include resp-margin(bottom);
					margin-bottom: $dist*0.5;
					@include resp-padding(h);
				}

				&-description {
					@include resp-margin(bottom);

					& > ul > li:before {
						color: $hc;
					}

					ul {
						ul {
							margin-left: 1.5em;

							li {
								&:before {
									@include fontawesome($fa-var-circle, solid);
									font-size: 0.3em;
									width: 1.25rem;
									margin-left: -1.25rem;
									top: 0.6rem
								}
							}
						}
					}
				}

				&-bottom-description {
					@include resp-margin(h, -1);
					@include resp-margin(bottom, -1);
					@include resp-padding(h);
					padding-top: $dist*0.5;
					padding-bottom: $dist*0.5;
					background: $box-color;
					border-top: 1px solid $border-color;

					p {
						border-bottom: 1px solid $border-color;
						@include resp-margin(h, -1);
						@include resp-padding(h);
						padding-bottom: $dist*0.5;
						margin-bottom: $dist*0.5;

						&:last-of-type {
							border-bottom: 0;
						}
					}
				}
			}

			&__link {
				text-align: center;
				position: absolute;
				bottom: calc(((#{$line-height} + #{math.div($dist, 2.5) * 2}) / 2) * -1);
				left: 0;
				right: 0;

				a {
					@extend %button
				}
			}

			&[style*="height:"] {
				background: #ffffff;

				.price-table__content-bottom-description {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					margin: 0;
				}
			}
		}
	}

	&.module--bg-dark {
		.price-tables-switch {
			//background: #ffffff; // uncomment to use toggle button

			span {
				&:first-of-type,
				&:last-of-type {
					color: #ffffff;
				}

				&.active {
					color: $ac;
				}
			}

			&__toggle {
				background: #f5f5f5;
			}
		}

		.price-table {
			border-color: $box-color-dark;
		}
	}

	&.module--bg-light {
		.price-tables-switch {
			//background: #ffffff; // uncomment to use toggle button

			&__toggle {
				background: $box-color;
				box-shadow: 0 0 0 1px $border-color-dark;
			}
		}

		.price-table {
			&,
			&__price,
			&__content-top-description,
			&__content-bottom-description,
			&__content-bottom-description p {
				border-color: $border-color-dark;
			}

			&__content{
				//&-top-description
				&-bottom-description {
					background: $border-color;
				}
			}
		}
	}
}