// - General
@use "sass:math";

.module--opinions {
  .opinions {
    position: relative;

    .opinion {
      @extend %cf;

      &__logo {
        float: left;
        border: 1px solid $border-color;
        padding: 10px;

        span {
          display: block;
          background: #ffffff;
          padding: $dist*0.5;
        }

        img {
          @include resp-img;
          margin: 0 auto;
        }
      }

      &__content {
        float: left;
        position: relative;

        &:before {
          @include fontawesome($fa-var-quote-left, solid);
          position: absolute;
          top: 0;
          font-size: math.div(50, $fs-root-int) + rem;
          color: $border-color;
        }

        p {
          font-size: $fs-h3;
          font-style: italic;
          margin-bottom: math.div($dist, 1.5);
        }

        strong {
          display: block;
        }
      }
    }
  }
}

// - Only outside columns or after breakpoint
& > .module--opinions,
.module--opinions.module--breakpoint-reached {
  .opinions {
    .opinion {
      &__logo {
        width: span(3 of 12) + gutter(12);
        margin-right: span(1 of 12);
      }

      &__content {
        width: span(8 of 12);
        padding-left: $dist;

        &:before {
          left: $dist*0.5;
          transform: translateX(-100%);
        }
      }

      @include breakpoint(null, $bp-s) {
        &__logo {
          width: 100%;
          float: none;
          margin: 0 auto $dist auto;
        }

        &__content {
          float: none;
          width: 100%;
          padding-left: $dist*2;

          &:before {
            left: $dist*1.5;
          }
        }
      }
    }
  }
}

// - Only in Columns above breakpoint
@include breakpoint($bp-columns, null) {
  .modular-content-column > .module--opinions {
    .opinions {
      .opinion {
        &__logo {
          width: 100%;
          max-width: 570px;
          margin-bottom: $dist*0.5;
        }

        &__content {
          width: auto;
          padding-left: $dist*2.5;
          max-width: 570px;

          &:before {
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}