@use "sass:math";

.news-teaser {
  position: relative;
  margin-bottom: $dist*3;

  @include breakpoint(null, $bp-m) {
    margin-bottom: $dist*1.5;
  }

  &:after {
    content: "";
    @extend %background-pattern;
    position: absolute;
    right: 0;
    top: 136px;
    bottom: 0;
    left: span(7 of 15);
    z-index: 0;

    @include breakpoint(null, $bp-l) {
      top: 106px;
      bottom: -$dist;
      left: span(4 of 15);
    }

    @include breakpoint(null, $bp-m) {
      left: span(3 of 15);
      top: 94px;
      bottom: math.div(-$dist, 1.5);
    }
  }

  &__inner {
    position: relative;
    @extend %outer-container;
    @extend %cf;
    z-index: 10;

    h2 {
      @extend %page-title-styling;
    }
  }

  &__text {
    float: left;
    width: span(4 of 15);
    margin-top: $dist*2;

    @include breakpoint(null, $bp-l) {
      width: 100%;
      float: none;
      max-width: span(13 of 15);
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    @include breakpoint(null, 810px) {
      max-width: 100%;
    }

    @include breakpoint(null, $bp-m) {
      margin-top: $dist;
    }

    &-inner {
      padding-left: $dist;
      border-left: 2px solid $border-color;

      @include breakpoint(null, $bp-l) {
        border-left: 0;
        padding-left: 0;
      }

      &:after {
        content: "";
        height: 2px;
        width: (100% + (#{$dist} + 2));
        background: $page-bg;
        border-bottom: 2px solid $border-color;
        display: block;
        padding-top: $dist;
        margin-left: -32px;

        @include breakpoint(null, $bp-l) {
          display: none
        }
      }
    }

   &-link {
     text-align: center;
     margin-top: $dist !important;
   }
  }

  .latest-news {
    float: right;
    width: span(10 of 15);
    margin: $dist*2 0;

    @include breakpoint(null, $bp-l) {
      width: 100%;
      float: none;
      max-width: span(13 of 15);
      margin-right: auto;
      margin-left: auto;
      margin-top: $dist;
    }

    @include breakpoint(null, 810px) {
      max-width: 100%;
    }

    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist;
    }

    & > h2 {
      @include resp-margin(bottom);
    }

    & > a {
      @include resp-margin(top);
    }

    &__children {
      @extend %cf;
    }

    &__child {
      width: 100%;
      position: relative;
      @extend %cf;
      margin-bottom: $dist;

      @include breakpoint(null, 540px) {
        max-width: 346px;
        margin-right: auto;
        margin-left: auto;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &-left {
        width: 346px;
        position: absolute;

        a {
          @include image-hover($fa-var-link);
          img {
            width: 100%;
            height: auto;
          }
        }

        @include breakpoint(null, 810px) {
          width: 296px;
        }

        @include breakpoint(null, 600px) {
          width: 200px;
        }
        @include breakpoint(null, 540px) {
          width: 100%;
          position: relative;
        }
      }

      &-right {
        width: calc(100% - (346px - (#{$dist} * 2)));
        float: right;
        background: $page-bg;
        padding-top: math.div($dist, 1.5);
        padding-right: $dist;
        padding-bottom: $dist;
        padding-left: calc(#{$dist} + #{$column-width});
        border-left: 2px solid $ac;

        @include breakpoint(null, 810px) {
          width: calc(100% - (296px - (#{$dist} * 2)));
        }

        @include breakpoint(null, 600px) {
          width: calc(100% - (200px - (#{$dist} * 2)));
        }

        @include breakpoint(null, 540px) {
          width: 100%;
          padding-left: $dist;
        }

        .date {
          font-size: $fs-small;
          color: $fc-light;
          font-weight: $fw-medium;
          font-family: $ff-hs;
          text-transform: uppercase;
        }

        h3 {
          font-size: $fs-h4;
          margin-bottom: $dist*0.5;
          margin-top: 0;
          font-weight: $fw-h-medium;

          @include breakpoint(null, $bp-xs) {
            font-size: $fs-h5;
          }

          a {
            color: $hc;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .button--small {
          margin-top: math.div($dist, 1.5);
          display: block;
        }
      }
    }
  }
}