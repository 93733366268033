.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: $dist*0.5;
  bottom: $dist*0.5;
  width: $dist*1.5;
  height: $dist*1.5;
  line-height: 2em;
  text-align: center;
  background: $ac;
  color: #ffffff !important;

  &:hover {
    background: $ac-hover;
  }

  i {
    &:before {
      position: relative;
      top: 4px;
      font-weight: $fw-medium;
    }
  }
}