@use "sass:math";

.shop-product {
	& > a {
		@include image-hover($fa-var-long-arrow-right);

		&:after,
		&:before {
			top: 35%;
		}

		&:hover {
			.shop-product__bottom {
				border-color: $ac;

				small,
				h3,
				span {
					color: #ffffff;
				}
			}
		}
	}

	&__top {
		position: relative;

		img {
			@include resp-img;
		}

		.shop-product-instock {
			position: absolute;
			right: $dist*0.25;
			bottom: $dist*0.25;
			z-index: 0;
		}
	}

	&__bottom {
		border: 2px solid $border-color;
		padding: $dist*0.5 $dist math.div($dist, 1.5) $dist;
		border-top: none;
		border-left: 2px solid $ac;

		@include breakpoint(null, $bp-l) {
			padding: $dist*0.5 math.div($dist, 1.5);
		}

		@include breakpoint(null, $bp-s) {
			padding: $dist*0.5;
		}

		small {
			text-transform: uppercase;
			font-family: $ff-hs;
			color: $fc-light;
			font-weight: $fw-medium;
		}

		h3 {
			&:first-of-type {
				font-size: $fs;
				font-weight: $fw-medium;
			}

			&:last-of-type {
				.product-price {

					&__value {
						font-size: $fs-h4;
						color: $hc;
						font-weight: $fw-h-medium;
						position: relative;

						&:after {
							content: "";
							width: $dist;
							height: 2px;
							background: $border-color;
							display: block;
							@include center(v);
							right: -$dist*1.5;
						}

						@include breakpoint(null, $bp-s) {
							font-size: $fs-h5;
						}
					}
				}
			}
		}
	}
}