@use "sass:math";

.shop-tabs {
	@extend %cf;

	&__menu {
		float: left;

		ul {
			border: 2px solid $border-color;
			padding-right: $dist;

			@include breakpoint(null, $bp-l) {
				padding-right: $dist*0.5;
			}

			li {
				cursor: pointer;
				transition: all $duration linear;
				font-size: $fs-h4;
				font-weight: $fw-h-medium;
				padding: $dist*0.5 0 0 $dist;
				margin-left: -2px;
				border-left: 2px solid $border-color;
				color: $hc;

				@include breakpoint(null, $bp-l) {
					font-size: $fs-h5;
					padding-left: $dist*0.5;
				}

				@include breakpoint(null, $bp-s) {
					padding-top: math.div($dist, 3);
					font-size: $fs-h6;
				}

				&.shop-tabs--active,
				&:hover {
					color: $ac;
					border-color: $ac;
				}

				&:after {
					content: "";
					height: 2px;
					width: 100%;
					background: $border-color;
					display: block;
					margin-top: $dist*0.5;

					@include breakpoint(null, $bp-s) {
						margin-top: math.div($dist, 3);
					}
				}

				&:last-of-type {
					padding-bottom: $dist*0.5;
					&:after {
						display: none;
					}

					@include breakpoint(null, $bp-s) {
						padding-bottom: math.div($dist, 3);
					}
				}
			}
		}
	}

	&__content {
		float: left;

		& > div {
			display: none;

			&.shop-tabs--active {
				display: block;
			}
		}
	}
}

body.has-sidebar {
	@include breakpoint($bp-xl, null) {
		.shop-tabs {
			&__menu {
				width: span(5 of 11);
				float: left;
				margin-right: gutter(11);
			}

			&__content {
				width: span(6 of 11);
				float: left;
			}
		}
	}

	@include breakpoint(null, $bp-xl) {
		.shop-tabs {
			&__menu {
				width: 100%;
				margin-right: 0;
				margin-bottom: $dist;
			}

			&__content {
				width: 100%;
			}
		}
	}

	@include breakpoint(null, $bp-m) {
		.shop-tabs {
			&__menu {
				margin-bottom: $dist*0.5;
			}
		}
	}
}

body:not(.has-sidebar) {
	.shop-tabs {
		&__menu {
			width: span(6 of 15);
			margin-right: gutter(15);

			@include breakpoint(null, $bp-l) {
				width: span(7 of 15);
			}

			@include breakpoint(null, $bp-m) {
				width: 100%;
				margin-bottom: $dist*0.5;
				margin-right: 0;
			}
		}

		&__content {
			width: span(9 of 15);

			@include breakpoint(null, $bp-l) {
				width: span(8 of 15);
			}

			@include breakpoint(null, $bp-m) {
				width: 100%;
			}
		}
	}
}