@use "sass:math";

#grasenhiller_accountpage {
	#Form_ProfileEditForm {
		border: 2px solid $border-color;
		padding: $dist;

		@include breakpoint(null, $bp-s) {
			padding: math.div($dist, 1.5);
		}
	}

	#Form_ChangePasswordForm,
	#Form_AddAddressForm{
		border: 2px solid $border-color;
		padding: $dist;

		@include breakpoint(null, $bp-s) {
			padding: math.div($dist, 1.5);
		}
	}

	#Form_AddAddressForm {
		.CompositeField {
			.fieldgroup-field {
				@include breakpoint(null, $bp-m) {
					width: 100%;
					margin-left: 0;
					float: none;
				}
			}

			.odd {
				margin-bottom: $dist*0.5;
			}
		}

		.btn-toolbar {
			.button--neutral {
				&:after {
					display: none;
				}
			}
		}
	}

	.account {
		& > h3,
		&__addresses > h3 {
			font-size: $fs-h4;
			font-family: $ff;
			font-weight: $fw-h-medium;
			margin-bottom: $dist*0.5;

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h5;
			}
		}

		&--profile {

		}

		&--addresses {

		}

		&__addresses {
			margin-bottom: $dist;

			.address {
				border: 2px solid $border-color;
				padding: $dist;
				margin-bottom: $dist;
				@extend %cf;

				@include breakpoint(null, $bp-s) {
					padding: math.div($dist, 1.5);
				}

				&:last-of-type {
					margin-bottom: 0;
				}

				&__left {
					width: span(6 of 11);
					float: left;
					border-right: 2px solid $border-color;
					margin-right: gutter(11);
					padding-right: $dist;

					@include breakpoint(null, $bp-m) {
						width: 100%;
						border-right: none;
						margin-right: 0;
						padding-right: 0;
						border-bottom: 2px solid $border-color;
						margin-bottom: math.div($dist, 1.5);
						padding-bottom: math.div($dist, 1.5);
					}

					@include breakpoint(null, $bp-s) {
						margin-bottom: $dist*0.5;
						padding-bottom: $dist*0.5;
					}
				}

				&__right {
					width: span(5 of 11);
					float: left;

					@include breakpoint(null, $bp-m) {
						width: 100%;
					}
				}

				&__actions {

					a {
						display: block;
						margin-bottom: $dist*0.5;
						color: $fc-light;
						text-transform: none;
						font-size: $fs;
						font-family: $ff;
						font-weight: $fw;

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&--orders {

		}
	}
}

.account--profile {
	@extend %cf;

	&__column {
		width: calc((100% - #{$dist}) / 2);
		float: left;
		margin-top: $dist;

		@include breakpoint(null, $bp-m) {
			width: 100%;

			&:first-of-type {
				margin-right: 0;
			}
		}

		h3 {
			font-weight: $fw-h-medium;
			font-size: $fs-h4;
			margin-bottom: math.div($dist, 1.5);

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h5;
			}
		}

		&:first-of-type {
			margin-right: $dist;
		}
	}
}