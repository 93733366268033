@use "sass:math";

.header {
  width: 100%;
  position: relative;
  z-index: 999;

  &__top {
    background: $ac;
    padding: 0 $dist*0.5;
    @extend %cf;

    @include breakpoint(null, 1150px) {
      font-size: $fs-small !important;
    }

    &-inner {
      max-width: $max-width;
      margin: 0 auto;
      width: 100%;
      @extend %cf;

      @include breakpoint(null, $bp-nav) {
        float: right;
        max-width: calc(100% - 231px);
      }

      @include breakpoint(null, 900px) {
        max-width: 100%;
      }

      li {
        cursor: pointer;
        padding: $dist*0.5 0;
        display: inline-block;
        transition: padding 0.2s linear;

        @include breakpoint($bp-l, $bp-s) {
          padding: math.div($dist, 3) 0;
        }

        a {
          color: #ffffff;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .left {
        float: left;

        @include breakpoint(null, 900px) {
          display: none;
        }

        a {
          color: #ffffff;

          &:hover {
            text-decoration: underline;
          }
        }

        li {
          margin-right: $dist;

          @include breakpoint(null, 1150px) {
            margin-right: math.div($dist, 3);
          }

          &:first-of-type {
            a {

              &:before {
                @include fontawesome($fa-var-phone, $style: light);
                margin-right: $dist*0.25;
              }
            }
          }

          &:last-of-type {
            a {

              &:before {
                @include fontawesome($fa-var-paper-plane, $style: light);
                margin-right: $dist*0.25;
              }
            }
          }
        }
      }

      .right {
        float: right;
        color: #ffffff;
        @extend %cf;

        li {
          margin-right: $dist;

          @include breakpoint(null, 1150px) {
            margin-right: math.div($dist, 3);
          }

          i {
            margin-right: $dist*0.25;
          }

          @include breakpoint(null, 550px) {
            font-size: 0;
            float: left;
            padding-left: 14px !important;
            padding-right: $dist*0.5 !important;
            margin-right: 0 !important;

            i {
              font-size: $fs-small !important;
              margin-right: math.div($dist, 6);
            }

            span {
              font-size: $fs-small !important;
              line-height: 1em;
            }
          }

          &:first-of-type {

            .shop-account-button {
              position: relative;
            }

            .s-widget__nav {
              opacity: 0;
              visibility: hidden;
              position: absolute;
              top: 42px;
              background: $ac-hover;
              z-index: 999;
              padding: math.div($dist, 3) math.div($dist, 1.5);
              transition: $duration;
              right: -34px;
              left: auto;

              @include breakpoint(null, 1150px) {
                top: 36px;
              }

              @include breakpoint(null, 550px) {
                display: none;
              }

              ul {
                li {
                  padding: 0 0 $dist*0.2 0;
                  margin: 0 0 $dist*0.2 0;
                  width: 100%;
                  border-bottom: 2px solid $ac;
                  margin-right: 0 !important;

                  &:before {
                    display: none;
                  }

                  a {
                    font-size: $fs;
                    font-weight: $fw;
                    font-family: $ff;
                    text-transform: none;
                    color: #ffffff;
                    white-space: nowrap;

                    @include breakpoint(null, 1150px) {
                      font-size: $fs-small;
                    }
                  }

                  &:last-of-type {
                    border-bottom: 0;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    background: none;
                  }

                  &:first-of-type {
                    position: static;
                  }
                }
              }
            }

            &:hover {
              .s-widget__nav {
                opacity: 1;
                visibility: visible;
              }
            }
          }

          &:last-of-type {
            padding-right: $dist;
            padding-left: $dist;
            background: $ac-hover;
            margin-right: 0;

            @include breakpoint(null, 1150px) {
              padding-right: math.div($dist, 3);
              padding-left: math.div($dist, 3);
            }
          }
        }
      }
    }
  }

  &__bottom {
    padding: $dist*2 $dist*0.5 47px $dist*0.5;
    transition: padding 0.2s linear;
    @extend %background-pattern;

    @include breakpoint(null, 1200px) {
      padding: $dist*1.5 $dist*0.5 35px $dist*0.5;
    }

    @include breakpoint(null, $bp-nav) {
      padding: $dist $dist*0.5;
    }

    @include breakpoint(null, $bp-s) {
      padding: math.div($dist, 1.5) $dist*0.5;
    }

    &-inner {
      max-width: $max-width;
      margin: 0 auto;
      @extend %cf;

      .left,
      .right {
        width: calc(((100% - 250px) / 2) - (#{$dist} * 2.5));

        @include breakpoint(null, $bp-xl) {
          width: calc(((100% - 250px) / 2) - #{$dist});
        }

        @include breakpoint(null, 1200px) {
          width: calc(((100% - 230px) / 2) - #{$dist});
        }

        @include breakpoint($bp-nav, $bp-l) {
          width: calc(((100% - 202px) / 2) - (#{$dist} / 2));
        }
      }

      .left {
        float: left;
        margin-right: $dist*2.5;

        @include breakpoint(null, $bp-xl) {
          margin-right: $dist;
        }

        @include breakpoint(null, $bp-nav) {
          display: none;
        }
      }

      .right {
        float: right;
        margin-left: $dist*2.5;

        @include breakpoint(null, $bp-xl) {
          margin-left: $dist;
        }

        @include breakpoint(null, $bp-nav) {
          margin: 0;
          text-align: right;
          float: right;
          width: auto;
        }
      }
    }
  }

  .header__logo {
    position: absolute;
    top: 0;
    left: $dist*0.5;
    display: block;
    text-decoration: none;
    padding: $dist*1.35 math.div($dist, 1.25);
    background: #ffffff;
    border-top: none;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
    transition: padding 0.2s linear;

    @include breakpoint($bp-nav, null) {
      @include center(h);
    }

    @include breakpoint(null, 1200px) {
      padding: $dist math.div($dist, 1.25);
    }

    @include breakpoint(null, $bp-l) {
      padding-left: math.div($dist, 1.5);
      padding-right: math.div($dist, 1.5);
    }

    @include breakpoint(null, $bp-nav) {
      padding: math.div($dist, 1.5);
    }

    @include breakpoint(null, $bp-s) {
      padding: $dist*0.5;
    }

    img {
      height: 137px;
      transition: $duration;

      @include breakpoint(null, 1200px) {
        max-width: 180px;
        height: 124px;
      }

      @include breakpoint(null, $bp-l) {
        max-width: 160px;
      }

      @include breakpoint(null, $bp-s) {
        max-width: 140px;
        height: 96px;
      }
    }
  }
}

.sticky-wrapper {
  @include breakpoint(1150px, null) {
    height: 199px !important;
  }
}

.sticky-scrolled {
  .header__bottom,
  .header__logo {
    box-shadow: 0 5px 10px -10px #000000ed;
  }

  @include breakpoint(1150px, null) {
    height: 129px !important;
  }

    @include breakpoint(1150px, null) {
      .header__top {
        li {
          padding-top: math.div($dist, 3);
          padding-bottom: math.div($dist, 3);
        }
      }

      .header__bottom {
        padding: $dist $dist*0.5 17px $dist*0.5;
      }

      .header__logo {
        padding: math.div($dist, 1.5) math.div($dist, 1.25);

        img {
          height: 109px;
        }
      }

      .s-widget__nav {
        top: 37px !important;
      }
    }
}