.page-title {
	text-align: center;

	h1 {
		@extend %page-title-styling;
	}

	.breadcrumbs {
		margin-bottom: $dist*0.25;
		color: $fc-light;
		font-family: $ff-hs;
		font-weight: $fw-medium;
		font-size: $fs-small;
		text-transform: uppercase;

		a {
			color: $fc-light;

			&:hover {
				color: $ac;
			}
		}
	}
}