@import "../../../node_modules/susy/sass/_susy.scss";
@import "../../../vendor/grasenhiller/silverstripe-tweaks/client/libraries/fontawesome/scss/_variables";
@import "globals/_variables";
@import "globals/_extends";
@import "globals/_mixins";
@import "globals/_text-styling";
@import "shop/partials/_add-to-cart-form.scss";
@import "shop/partials/_address.scss";
@import "shop/partials/_cartform.scss";
@import "shop/partials/_categories.scss";
@import "shop/partials/_category.scss";
@import "shop/partials/_checkoutform.scss";
@import "shop/partials/_forms.scss";
@import "shop/partials/_general.scss";
@import "shop/partials/_instock.scss";
@import "shop/partials/_one-time-messages.scss";
@import "shop/partials/_order-summary.scss";
@import "shop/partials/_placed-orders.scss";
@import "shop/partials/_product.scss";
@import "shop/partials/_products.scss";
@import "shop/partials/_shop-global-notice.scss";
@import "shop/partials/_tabs.scss";
@import "shop/pages/_accountpage.scss";
@import "shop/pages/_cartpage.scss";
@import "shop/pages/_categorypage.scss";
@import "shop/pages/_checkoutpage.scss";
@import "shop/pages/_login-page.scss";
@import "shop/pages/_productpage.scss";
@import "shop/pages/_shopholder.scss";

// - Set box-sizing to border-box
*,
*:after,
*:before {
	box-sizing: border-box;
}

// - Fix small gutters of images
img {
	vertical-align: top;
}

// - Remove focus
*:focus {
	outline: 0;
}

// - IE + Edge ... whoop whoop
main,
.main {
	display: block;
}

// - Set page bg color
body {
	background: $page-bg;
}

html {
	background: $footer-bg;
}

.outer-wrapper {
	@extend %outer-container;
	margin-top: $dist*3;
	margin-bottom: $dist*3;

	@include breakpoint(null, $bp-m) {
		margin-top: $dist*1.5;
		margin-bottom: $dist*1.5;
	}
}

$bp-columns: 1020px;

.outer-wrapper .modular-content .module--as-popup {
	display: none !important;
}

.secure-files-msg {
	@include resp-margin(top);

	&,
	& * {
		text-align: left !important;
	}
}

.modular-content {
	.module {
		position: relative;
		margin-bottom: $dist*2;

		@include breakpoint(null, $bp-m) {
			margin-bottom: $dist*1.5;
		}

		@include breakpoint(null, $bp-s) {
			margin-bottom: $dist;
		}

		&:hover {
			.module__editlink {
				opacity: 0.625;
			}
		}

		&__editlink {
			opacity: 0.25;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.5em 1em;
			color: #ffffff;
			border-radius: 5px;
			background: $ac;
			transition: opacity $duration linear, background $duration linear;

			i {
				margin-right: 0.5em;
			}

			&:hover {
				opacity: 1 !important;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&--separator-top {
			padding-top: $dist*2;
			border-top: 2px solid $border-color;

			 @include breakpoint(null, $bp-m) {
				 padding-top: $dist*1.5;
			 }

			@include breakpoint(null, $bp-s) {
				padding-top: $dist;
			}

			.module__editlink {
				top: $dist;

				@include breakpoint(null, $bp-m) {
					top: $dist*0.5;
				}
			}
		}

		&--separator-bottom {
			padding-bottom: $dist*2;
			border-bottom: 2px solid $border-color;

			@include breakpoint(null, $bp-m) {
				padding-bottom: $dist*1.5;
			}

			@include breakpoint(null, $bp-s) {
				padding-bottom: $dist;
			}
		}

		&:first-of-type {
			&.module--separator-top {
				padding-top: 0;
				border-top: 0;
			}
		}

		&:last-of-type {
			margin-bottom: 0;

			&.module--separator-bottom {
				padding-bottom: 0;
				border-bottom: 0;
			}
		}

		&__title {
			@include resp-margin(bottom);

			h3 {
				font-size: $fs-h3;
				color: $hc;
				font-family: $ff-h;

				@include breakpoint(null, $bp-m) {
					font-size: $fs-h4;
				}
			}
		}
	}

	.modular-content-columns {
		@extend %cf;
		@include resp-margin(bottom);

		.modular-content-column {
			opacity: 1;
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	&.featherlight-inner {
		margin-top: 0 !important;
	}

	
@import "modular-content-partials/_accordion.scss";
@import "modular-content-partials/_column.scss";
@import "modular-content-partials/_downloads.scss";
@import "modular-content-partials/_form.scss";
@import "modular-content-partials/_gallery.scss";
@import "modular-content-partials/_grid.scss";
@import "modular-content-partials/_headline.scss";
@import "modular-content-partials/_logos.scss";
@import "modular-content-partials/_newsletter.scss";
@import "modular-content-partials/_opinions.scss";
@import "modular-content-partials/_pricetables.scss";
@import "modular-content-partials/_secure-files.scss";
@import "modular-content-partials/_slick.scss";
@import "modular-content-partials/_textimage.scss";
}


@import "partials/_contact-person.scss";
@import "partials/_cookie-consent.scss";
@import "partials/_footer.scss";
@import "partials/_form.scss";
@import "partials/_header.scss";
@import "partials/_latest-news.scss";
@import "partials/_loader.scss";
@import "partials/_mobile-navigation.scss";
@import "partials/_navigation-footer.scss";
@import "partials/_navigation-main.scss";
@import "partials/_navigation-mobile.scss";
@import "partials/_navigation.scss";
@import "partials/_news-holder.scss";
@import "partials/_news-page.scss";
@import "partials/_page-media.scss";
@import "partials/_page-slider.scss";
@import "partials/_page-title.scss";
@import "partials/_pagination.scss";
@import "partials/_sidebar.scss";
@import "partials/_team-member.scss";
@import "partials/_teaserboxes-homepage.scss";
@import "partials/_totop.scss";
@import "partials/_userforms.scss";
@import "pages/_basic-holder.scss";
@import "pages/_contactpage.scss";
@import "pages/_homepage.scss";
@import "pages/_team-page.scss";
