@use "sass:math";

.module {
	.slick-slide {
		& > div > * {
			padding-left: $dist*0.5;
			padding-right: $dist*0.5;
		}
	}

	.slick-list {
		margin-right: -$dist*0.5;
		margin-left: -$dist*0.5;
		overflow: hidden;
	}

	.slick-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		font-size: $fs-h2;
		transition: color $duration linear;
		margin-top: -(math.div($dist, 2.5) + $dist*0.5) * 0.5;
		background: #ffffff;
		z-index: 50;

		&:before {
			@include center(v);
		}

		&.slick-disabled {
			color: $fc-light;
			cursor: default;
		}

		&:not(.slick-disabled) {
			cursor: pointer;

			&:hover {
				color: $ac;
			}
		}

		&.fa-chevron-left {
			left: -$fs-h2;
			text-align: left;
		}

		&.fa-chevron-right {
			right: -$fs-h2;
		}
	}

	.slick-dots {
		margin-top: $dist*0.5;
		font-size: 0;
		text-align: center;

		li {
			display: inline-block;
			margin-right: 2px;

			&:last-of-type {
				margin-right: 0;

				&:first-of-type {
					display: none;
				}
			}

			button {
				cursor: pointer;
				display: block;
				width: math.div($dist, 2.5);
				height: math.div($dist, 2.5);
				background: $box-color;
				border-radius: 100%;
				font-size: 0;
				border: 0;
				box-shadow: none;
				line-height: 1;

				&:hover {
					background: $box-color-dark;
				}
			}

			&.slick-active {
				button {
					background: $hc;

					&:hover {
						background: darken($hc, 10%);
					}
				}
			}
		}
	}
}